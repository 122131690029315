import React, { createContext, useContext, useEffect, useMemo, useRef } from "react";
import useMouse from "@react-hook/mouse-position";

const initialState = {
  currentMousePosition: {
    x: 0,
    y: 0,
  },
};

const MousePosition = createContext(initialState);

export function useMousePosition() {
  return useContext(MousePosition);
}

/**
 * This component manages the mouse position context.
 */
export function MousePositionProvider({ children }) {
  const mousePosition = useMouse(document.body, { fps: 10 });
  const currentMousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    currentMousePosition.current = mousePosition;
  }, [mousePosition]);

  const value = useMemo(
    () => ({
      currentMousePosition: currentMousePosition.current,
    }),
    [currentMousePosition.current]
  );

  return <MousePosition.Provider value={value} children={children} />;
}
