import moment, { Moment } from "moment";

function internalFormat(date: Moment, format: string, language: string) {
  const locale = language === "no" ? "nb" : language || "en";
  return date.isValid() ? date.locale(locale).format(format) : "";
}

export function formatMomentRange(
  startDate?: string,
  endDate?: string,
  momentFormat: string = "YYYY-MM-DD",
  options: { language?: string; delimiter?: string; indicateOngoing?: boolean; ongoingText?: string } = {
    language: "en",
    delimiter: " – ",
    indicateOngoing: true,
    ongoingText: "",
  }
) {
  // Note that sending undefined to the moment constructor will return a valid moment with the current date. This is not what we want here.
  const momentStartDate = moment(startDate || "0000-00-00");
  const momentEndDate = moment(endDate || "0000-00-00");

  if (!momentStartDate.isValid() && !momentEndDate.isValid()) return "";

  const language = options.language || "en";
  const delimiter = options.delimiter ?? " – ";
  const indicateOngoing = options.indicateOngoing ?? true;
  const ongoingText = options.ongoingText || "";

  const formatStart = internalFormat(momentStartDate, momentFormat, language);
  const formatEnd = internalFormat(momentEndDate, momentFormat, language);

  if (formatStart === formatEnd) return formatStart;
  if (!formatEnd && !indicateOngoing) return formatStart;
  return `${[formatStart || "", formatEnd || ""].join(delimiter)}${
    indicateOngoing && !formatEnd ? ongoingText : ""
  }`.trim();
}

export function formatMoment(
  date?: string,
  momentFormat: string = "YYYY-MM-DD",
  options: {
    language?: string;
  } = { language: "en" }
) {
  const momentDate = moment(date || "0000-00-00");
  if (!momentDate.isValid()) return "";
  return internalFormat(moment(date), momentFormat, options?.language || "en");
}
