import React from "react";
import { Segment } from "semantic-ui-react";

export function SmartContent(props) {
  return (
    <Segment basic={props.basic} style={{ padding: "0", background: "transparent" }}>
      {props.children}
    </Segment>
  );
}
