import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Loader, Segment } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GiveFeedback } from "../components/GiveFeedback";
import { ContactSmartProfile } from "../components/ContactSmartProfile";
import useOnScreen from "../hooks/useOnScreen";

import { callWithSpinnerAndNotifyOnError, getDownloadAsFileName } from "../Util";
import { SmartContainer } from "../components/smartComponents/SmartContainer";
import { SmartHeader } from "../components/smartComponents/SmartHeader";
import { SmartFooter } from "../components/smartComponents/SmartFooter";
import { SmartContent } from "../components/smartComponents/SmartContent";
import { SmartNotFound } from "../components/smartComponents/SmartNotFound";
import { ProfileRenderer } from "../render-templates/ProfileRenderer";
import { SmartNdaModal } from "../components/smartComponents/SmartNdaModal";
import { useStoredState } from "../hooks/useStoredState";
import { NDA_ACCEPTED } from "../services/storageService";
import { PdfPreview } from "../components/PdfPreview";
import {
  downloadProfileAttachmentFromShare,
  downloadProfilePdfFromShare,
  getProfileFromShare,
  getProfilePdfAttachmentFromShare,
} from "../services/profileServicePublic";

export function PageSmartProfile() {
  const { shareSlug } = useParams();
  const [t, i18n] = useTranslation();
  const [profile, setProfile] = useState();
  const [downloading, setDownloading] = useState(false);
  const [downloadingAttachment, setDownloadingAttachment] = useState(false);
  const [smartProfileNotFound, setSmartProfileNotFound] = useState(false);
  const [nda, setNda] = useState();
  const [ndaAccepted, setNdaAccepted] = useStoredState(NDA_ACCEPTED, undefined, { storePerPath: true });
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const contactDetailsRef = useRef();
  const buttonIsDisabled = useOnScreen(contactDetailsRef);

  useEffect(() => {
    getProfileFromShare(shareSlug, ndaAccepted)
      .then(setProfile)
      .catch((error) => {
        if (error?.response?.status === 451) setNda(error.response.data?.nda);
        else setSmartProfileNotFound(true);
      });
  }, [shareSlug, ndaAccepted]);

  useEffect(() => {
    if (profile?.language) i18n.changeLanguage(profile.language);
  }, [i18n, profile]);

  async function downloadPdf() {
    await callWithSpinnerAndNotifyOnError(
      () => downloadProfilePdfFromShare(shareSlug, getDownloadAsFileName(profile)),
      setDownloading
    );
  }

  async function downloadAttachment() {
    await callWithSpinnerAndNotifyOnError(
      () => downloadProfileAttachmentFromShare(shareSlug, `${getDownloadAsFileName(profile)} - attachment`),
      setDownloadingAttachment
    );
  }

  const scrollToElement = (targetElement) => {
    const element = document.getElementById(targetElement);
    element.scrollIntoView({ behavior: "smooth" });
  };

  window.onscroll = function () {
    const pageHeight = window.innerHeight;
    window.scrollY >= pageHeight + 400 && setIsSticky(true);
    // ? sticky always/when scrolled 100 vh or reset when scrolling upwards?
  };

  if (smartProfileNotFound)
    return (
      <SmartNotFound
        title={t("page.smartProfile.notFoundTitle")}
        header={t("page.smartProfile.notFoundHeader")}
        message={
          <Trans
            i18nKey="page.smartProfile.notFoundText"
            // eslint-disable-next-line
            components={[<a href="mailto:info@momang.io" />]}
            values={{ email: "info@momang.io" }}
          />
        }
      />
    );

  if (nda)
    return (
      <SmartNdaModal
        nda={nda}
        onAccept={() => {
          setNda(null);
          setNdaAccepted(true);
        }}
      />
    );

  if (!profile) return <Loader active />;

  const downloadPdfButton = profile.hasContent ? (
    <Button
      secondary
      icon="file pdf"
      content={t("component.share.downloadPdf")}
      disabled={downloading}
      loading={downloading}
      onClick={downloadPdf}
    />
  ) : null;
  const downloadAttachmentButton = profile.hasAttachment ? (
    <Button
      secondary
      icon="attach"
      content={profile.hasContent ? t("component.share.downloadAttachment") : t("component.share.downloadPdf")}
      disabled={downloadingAttachment}
      loading={downloadingAttachment}
      onClick={downloadAttachment}
      style={{ marginLeft: "12px" }}
    />
  ) : null;
  const consultantName = profile.content?.consultant || profile.derivedContent?.user?.name;

  const actions = [];
  if (profile.displaySmartProfileFeedback)
    actions.push(
      <Button
        style={{ marginRight: "8px" }}
        onClick={() => scrollToElement("contactSeller")}
        secondary
        icon="mail"
        disabled={buttonIsDisabled}
        content={t("page.smartProfile.showContactDetails")}
      />
    );
  actions.push(downloadPdfButton, downloadAttachmentButton);

  return (
    <>
      <Helmet>
        <title>{consultantName || t("page.smartProfile.title")}</title>
      </Helmet>
      <SmartContainer>
        <SmartHeader title={consultantName} actions={actions} />
        {profile.hasContent && (
          <SmartContent>
            <ProfileRenderer
              renderTemplate={profile.defaultRenderTemplate}
              customTheme={profile.customTheme}
              profileContent={profile.content}
              derivedContent={profile.derivedContent}
              profileName={profile.name}
              language={profile.language}
              renderAsSmartProfile
            />
          </SmartContent>
        )}
        {profile.hasAttachment && !profile.hasContent && (
          <PdfPreview profileId={shareSlug} fetchPdfFn={getProfilePdfAttachmentFromShare} />
        )}
        {profile.displaySmartProfileFeedback && (
          <>
            <GiveFeedback
              isSticky={isSticky}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              shareSlug={shareSlug}
              profileOrganizationSlug={profile?.organizationSlug}
              profileOwnerUserSlug={profile?.userSlug}
              consultantName={consultantName}
              profileId={profile?.id}
            />
            <div id="contactSeller" ref={contactDetailsRef}>
              <ContactSmartProfile user={profile?.shareCreatedByUser} />
            </div>
          </>
        )}
        {(downloadPdfButton || downloadAttachmentButton) && (
          <Segment>
            {downloadPdfButton}
            {downloadAttachmentButton}
          </Segment>
        )}
        <SmartFooter />
      </SmartContainer>
    </>
  );
}
