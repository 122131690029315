import React from "react";
import { Menu } from "semantic-ui-react";

export function SmartHeader({ title, actions }) {
  return (
    <Menu borderless stackable>
      <Menu.Item>{title}</Menu.Item>
      <Menu.Item position="right">
        <Menu stackable secondary>
          {actions?.map((action, index) => (
            <Menu.Item key={index} content={action} />
          ))}
        </Menu>
      </Menu.Item>
    </Menu>
  );
}
