import React from "react";
import styled from "styled-components";

type HTMLProps = {
  small: boolean;
  allowEmptyParagraphs: boolean;
};

const HTML = styled.div<HTMLProps>`
  font-size: ${({ small }) => (small ? "1rem" : "1.3rem")};
  margin-bottom: 2rem;

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.4em;
    font-weight: bold;
  }

  h4 {
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul li,
  ol li {
    margin-left: 0 !important;
  }

  @media ${({ theme }) => theme.breakpoint.tablet} {
    font-size: ${({ small }) => (small ? "1.1rem" : "1.4rem")};

    h1 {
      font-size: 2.6em;
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-weight: normal;
    font-style: italic;
  }

  strong em {
    font-weight: bold;
    font-style: italic;
  }

  word-wrap: anywhere; // Prevents long words from increasing the container width and causing layout issues

  p:empty {
    min-height: ${({ small, allowEmptyParagraphs }) => {
      if (allowEmptyParagraphs) return small ? "1rem" : "1.3rem";
      return "0";
    }};
  }
`;

export function RichText({
  html,
  small,
  editorFieldId,
  style,
  allowEmptyParagraphs = false
}: {
  html?: string;
  small?: boolean;
  editorFieldId?: string;
  style?: React.CSSProperties;
  allowEmptyParagraphs?: boolean;
}) {
  if (!html) return null;
  return (
    <HTML
      data-editor-field-id={editorFieldId}
      dangerouslySetInnerHTML={{ __html: html }}
      small={Boolean(small)}
      style={style}
      allowEmptyParagraphs={Boolean(allowEmptyParagraphs)}
    />
  );
}
