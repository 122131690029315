import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Segment } from "semantic-ui-react";
import { Auth } from "@aws-amplify/auth";
import { getUsername } from "../services/auth";
import { localDeleteAll } from "../services/storageService";

const FullPage = styled.div`
  min-height: 100vh;
  background-color: #ffd2d5;
`;

export function NoMansLand() {
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUsername()
      .then((u) => {
        setUserName(u);
        Auth.signOut().finally(localDeleteAll);
      })
      .catch((e) => {
        console.error("NoMansLand: ", e);
        Auth.signOut().finally(() => {
          localDeleteAll();
          window.location = "/";
        });
      });
  }, [navigate]);

  return (
    <FullPage>
      <Container text>
        <Segment>
          <h1>Welcome to No Man's Land</h1>
          <p>
            You have signed in as <code>{userName}</code>, an account that doesn't belong to any Momang organization.
            This could happen if you used a third party provider, such as Google or Microsoft.
          </p>
          <p>
            If you are the first one out in your company and wish to try Momang, please head over to{" "}
            <a href="https://www.momang.io/signup">our signup page</a> and register a new organization.
          </p>
          <p>
            If you on the other hand believe you should already have access to Momang, please contact your company's
            administrator and ask them to invite you.
          </p>
          <p>
            You can also try clearing your web browser's cache and cookies, and then attempt to sign in again. For more
            information, check out our <a href="https://www.momang.io/faq">FAQ</a>.
          </p>
          <p>
            Also, you can always reach us at <a href="mailto:info@momang.io">info@momang.io</a> or drop us a line in the
            chat below and let us help you.
          </p>
          <Button content="Sign in again" onClick={() => navigate("/")} />
        </Segment>
      </Container>
    </FullPage>
  );
}
