import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { getDefaultRenderTemplate, getProfile } from "../services/profileService";
import { ProfileRenderer } from "../render-templates/ProfileRenderer";

function PageRender() {
  const { profileId } = useParams();
  const { search } = useLocation();
  const hyphenation = useMemo(() => new URLSearchParams(search).get("hyphenation"), [search]);
  const [profile, setProfile] = useState();
  const [renderTemplate, setRenderTemplate] = useState();

  useEffect(() => {
    async function fetchProfile() {
      try {
        const newProfile = await getProfile(profileId);
        setProfile(newProfile);
        setRenderTemplate(await getDefaultRenderTemplate(newProfile));
      } catch (e) {
        console.error(e);
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    fetchProfile();
  }, [profileId]);

  return (
    <ProfileRenderer
      renderTemplate={renderTemplate}
      customTheme={profile?.customTheme}
      profileContent={profile?.content}
      derivedContent={profile?.derivedContent}
      profileName={profile?.name}
      language={profile?.language}
      renderForPdf
      // Hyphenopoly is intended to be used as a polyfill for browsers that don't support hyphenation natively and
      // is needed for Chromium running on our Lambda. Weirdly, it doesn't (quite) respect the CSS hyphens property
      // when it is set to "none", so we need to be able to disable it on a per-render-template basis.
      hyphenation={hyphenation !== "false" && renderTemplate?.options?.useHyphenopoly !== false}
    />
  );
}

export default withAuthenticator(PageRender);
