import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

export const PAGE_WIDTH = {
  compact: "compact",
  full: "full",
  fullMenu: "fullMenu",
};

const initialState = {
  pageWidth: PAGE_WIDTH.compact,
};

const DisplayContext = createContext(initialState);

export function useDisplayContext() {
  return useContext(DisplayContext);
}

export function useFullWidthPage() {
  const { setPageWidth } = useDisplayContext();

  useEffect(() => {
    setPageWidth(PAGE_WIDTH.full);
    return () => setPageWidth(PAGE_WIDTH.compact);
  });
}

export function useNoChatWidgetSpace() {
  const { setAddSpaceForChatWidget } = useDisplayContext();

  useEffect(() => {
    setAddSpaceForChatWidget(false);
    return () => setAddSpaceForChatWidget(true);
  });
}

/**
 * This component manages the display configuration context.
 */
export function DisplayContextProvider({ children }) {
  const [pageWidth, setPageWidth] = useState(PAGE_WIDTH.compact);
  const [addSpaceForChatWidget, setAddSpaceForChatWidget] = useState(true);

  const value = useMemo(
    () => ({
      pageWidth,
      setPageWidth,
      addSpaceForChatWidget,
      setAddSpaceForChatWidget,
    }),
    [pageWidth, addSpaceForChatWidget]
  );

  return <DisplayContext.Provider value={value} children={children} />;
}
