import moment from "moment";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import slugify from "slugify";
import _ from "lodash";
import { ERROR, notifyUser } from "./services/notificationService";
import { CancelablePromise } from "cancelable-promise";
import { joinStrings } from "./render-templates/Utils";
import { v4 as uuidv4 } from "uuid";
import { getJwt } from "./services/auth";

/**
 * Convenience function for calling a long-running function and updating a status flag. Typically used for
 * showing a progress spinner in the GUI when performing backend operations.
 * @param action a long-running async function
 * @param spinnerSetter a setter function that expects one boolean input parameter
 * @returns {Promise<void>}
 */
export async function callWithSpinner(action, spinnerSetter) {
  let result;
  try {
    spinnerSetter(true);
    result = await action();
  } catch (e) {
    if (e.message !== "canceled") {
      console.error("Error caught in callWithSpinner", e);
      throw e;
    }
  } finally {
    spinnerSetter(false);
  }
  return result;
}

/**
 * Wrapper to handle user feedback when an operation throws an error. The given function `fn` is called and in case any
 * exception is thrown, an error notification is shown to the user. Optionally, the title and message can be given as
 * parameters.
 * Returns the result of the given function or `true` if the call was successful but did not return any data.
 */
export async function notifyOnError(fn, customErrorTitle = null, customErrorMessage = null) {
  try {
    return (await fn()) || true;
  } catch (error) {
    console.error(error);
    notifyUser(customErrorTitle || "Oh, no!", customErrorMessage || "Something went wrong.", {
      type: ERROR,
      technicalMessage: joinStrings([error.toString(), error.response?.request?.responseURL], "\n"),
    });
  }
  return false;
}

export function callWithSpinnerAndNotifyOnError(
  action,
  spinnerSetter,
  options = { customErrorTitle: null, customErrorMessage: null }
) {
  return new CancelablePromise((resolve) =>
    resolve(
      notifyOnError(
        async () => callWithSpinner(action, spinnerSetter),
        options?.customErrorTitle,
        options?.customErrorMessage
      )
    )
  );
}

/**
 * Converts a JS File object to a base64 encoded string.
 */
export async function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function fetchWithJwt(url) {
  const token = await getJwt();
  const data = await fetch(url, {
    headers: {
      Authorization: `${token}`,
    },
  });
  return await data.json();
}

export function getDownloadAsFileName(profile) {
  const name = profile?.content?.consultant || profile?.derivedContent?.user?.name || "profile";
  const date = moment(profile?.updatedAt).format("YYYY-MM-DD");
  return `${date} - ${name}`;
}

export function triggerDownloadFromUrl(url, downloadAsFileName) {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", downloadAsFileName);
  document.body.appendChild(link);
  link.click();
}

export function getExternalCandidateAttachmentDownloadAsFileName(lead, externalCandidate) {
  const leadTitle = lead.title || "lead";
  const candidateName = externalCandidate.candidateName || "external candidate";
  const date = moment(externalCandidate?.updatedAt).format("YYYY-MM-DD");
  return `${date} - ${leadTitle} - ${candidateName}`;
}

export function openInNewTab(url) {
  // Navigation fails for some reason if we don't use a timeout here.
  setTimeout(() => window.open(url, "_blank"), 0);
}

export function htmlToDraftJs(html) {
  const content = htmlToDraft(html);
  const { contentBlocks, entityMap } = content;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
}

export function nameToEmail(name, domain) {
  slugify.extend({ " ": "." });
  return `${slugify(_.trim(name), {
    lower: true,
    remove: /[^a-zA-Z0-9_.]/g,
  })}@${domain}`;
}

export function ensureDate(dateOrString) {
  if (typeof dateOrString === "string" && !Number.isNaN(Date.parse(dateOrString))) return new Date(dateOrString);
  return dateOrString;
}

function formatMomentRange(startDate, endDate, momentFormat) {
  if (!startDate && !endDate) return "";

  const formatStart = startDate && moment(startDate).format(momentFormat);
  const formatEnd = endDate && moment(endDate).subtract(1, "day").format(momentFormat);

  if (formatStart === formatEnd) return formatStart;
  return [formatStart || "", formatEnd || ""].join(" – ").trim();
}

export function formatDateRange(startDate, endDate) {
  return formatMomentRange(startDate, endDate, "L");
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function percentToHoursPerWeek(percent, workHoursPerDay = 8) {
  return _.round((percent / 100) * (5 * workHoursPerDay), 1);
}

export function hoursPerWeekToPercent(hours, workHoursPerDay = 8) {
  return _.round((hours / (5 * workHoursPerDay)) * 100, 1);
}

export function convertToHtml(str) {
  return str ? `<p>${str.replaceAll(/\n+/g, "</p><p>")}</p>` : "";
}

export function stripHtmlTags(value) {
  if (_.isString(value)) {
    return value.replace(/<[^>]*>?/gm, "");
  }
}

export function isAllowedToEdit(isAdmin, isSales, authenticatedUserSlug, userSlug) {
  return isAdmin || isSales || authenticatedUserSlug === userSlug;
}

export function isAdvancedSearchQuery(query) {
  const regex = /\b(AND|OR|NOT)\b/i;
  return regex.test(query);
}

export function slug(input) {
  slugify.extend({
    "@": " at ",
    ".": " ",
    "#": "_",
  });

  return slugify(input, {
    lower: true,
    remove: /[^a-zA-Z0-9 _]/g,
  });
}

export function slugWithHash(input) {
  return `${slug(input)}-${uuidv4().substring(0, 5)}`;
}

/**
 * Creates an object from URL search parameters.
 * This function converts URL search parameters into an object.
 *
 * @param {URLSearchParams} searchParams - The URL search parameters.
 * @param {Array} excludeKeysFromArrayCheck - The keys that should be excluded from the array check; i.e. values that can include the value "," and should be treated as a string.
 * @returns {{[key: string]: any}} - The object created from the URL search parameters.
 */
export function createObjectFromUrlSearchParams(searchParams, excludeKeysFromArrayCheck = ["query"]) {
  const searchParamsObject = {};
  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
  for (const [key, value] of Object.entries(Object.fromEntries(searchParams))) {
    if (!_.isEmpty(value)) {
      if (excludeKeysFromArrayCheck.includes(key)) searchParamsObject[key] = value;
      else if (/^\d+$/.test(value)) _.set(searchParamsObject, key, parseInt(value, 10));
      else if (dateRegex.test(value)) _.set(searchParamsObject, key, new Date(value));
      else _.set(searchParamsObject, key, Array.from(new Set(value.split(",").filter((v) => !!v))));
    }
  }
  return searchParamsObject;
}

/**
 * Creates a URLSearchParams object from an object of filters.
 *
 * @param {{[key: string]: any}} filters - The object containing the filters.
 * @returns {URLSearchParams} - The formatted URLSearchParams object.
 */
export function createUrlSearchParamsFromObject(filters) {
  const formattedSearchParams = new URLSearchParams();
  const flattenObject = (obj, prefix = "") => {
    for (const [key, value] of Object.entries(obj || {})) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (value instanceof Date) formattedSearchParams.set(newKey, moment(value).format());
      else if (typeof value === "object" && !Array.isArray(value)) flattenObject(value, newKey);
      else if (Array.isArray(value)) formattedSearchParams.set(newKey, value.join(","));
      else formattedSearchParams.set(newKey, value);
    }
  };
  flattenObject(filters);
  return formattedSearchParams;
}
