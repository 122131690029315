import React from "react";
import { createRoot } from "react-dom/client";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

import "semantic-ui-less/semantic.less";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "./i18n";
import MomangLoader from "./MomangLoader";
import { getAwsConfig } from "./services/auth";
import { Auth } from "@aws-amplify/auth";

const rootElement = document.getElementById("root");
if (rootElement) {
  getAwsConfig()
    .then((awsConfig) => {
      Auth.configure(awsConfig);
    })
    .then(() => {
      const root = createRoot(rootElement);
      root.render(<MomangLoader />);
    });
}

unregisterServiceWorker();
