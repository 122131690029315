import _ from "lodash";

import { User } from "../contexts/models";

export function isMemberOf(user: User, role: string) {
  return _.indexOf(user?.roles, role) >= 0;
}

export function hasOneOf(user: User, roles: string[]) {
  return !_.isEmpty(_.intersection(user?.roles, roles));
}
