import React from "react";
import { DatePicker as RsDatePicker } from "rsuite";
import "./RsDatePicker.less";
import moment from "moment";

// This component wraps the RSuite datepicker component and adds styling and behavior to match Semantic UI.
export function DatePicker({ clearable = false, format = "yyyy-MM-dd", placeholder = "YYYY-MM-DD", ...props }) {
  const weekdays = moment().localeData().weekdaysShort();
  const locale = {
    monday: weekdays[1],
    tuesday: weekdays[2],
    wednesday: weekdays[3],
    thursday: weekdays[4],
    friday: weekdays[5],
    saturday: weekdays[6],
    sunday: weekdays[0],
  };

  return (
    <RsDatePicker
      {...props}
      cleanable={clearable}
      showWeekNumbers
      isoWeek
      block
      format={format}
      locale={locale}
      placement="autoVerticalStart"
      placeholder={placeholder}
      oneTap={format === "yyyy-MM-dd"}
    />
  );
}
