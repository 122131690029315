import { useState, useEffect } from "react";
export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const intersectionObserverOptions = {
    root: null,
    threshold: 1,
  };

  const handleIntersect = (entries) => {
    const [entry] = entries;
    setIntersecting(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, intersectionObserverOptions);
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.disconnect();
    };
  }, [ref, intersectionObserverOptions]);

  return isIntersecting;
}
