import React from "react";
import styled from "styled-components";
import momangLogo from "../assets/svgs/momang-m.svg";

const AnimatedSVG = styled.img`
  position: fixed;
  z-index: 1000;

  opacity: 0;
  animation: fadeInOut 3s ease infinite;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
      --size: 128px;
      width: var(--size);
      height: var(--size);
      top: calc(50% - var(--size) / 2);
      left: calc(50% - var(--size) / 2);
    }
    50% {
      opacity: 1;
      --size: 150px;
      width: var(--size);
      height: var(--size);
      top: calc(50% - var(--size) / 2);
      left: calc(50% - var(--size) / 2);
    }
    100% {
      opacity: 0;
      --size: 128px;
      width: var(--size);
      height: var(--size);
      top: calc(50% - var(--size) / 2);
      left: calc(50% - var(--size) / 2);
    }
  }
`;

export const AnimatedAppLoader = () => {
  return <AnimatedSVG src={momangLogo} alt="Momang Logo" />;
};
