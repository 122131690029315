import _ from "lodash";

export const TEN_SECONDS_IN_MILLISECONDS = 10 * 1000;
export const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
export const FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * 60 * 1000;
export const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
export const noCache = {
  ignoreCache: true,
  maxAge: 0,
  exclude: {
    query: true,
  },
};
export const cache10s = {
  ignoreCache: false,
  maxAge: TEN_SECONDS_IN_MILLISECONDS,
  exclude: {
    query: false,
  },
};
export const cache5min = {
  ignoreCache: false,
  maxAge: FIVE_MINUTES_IN_MILLISECONDS,
  exclude: {
    query: false,
  },
};
export const cache15min = {
  ignoreCache: false,
  maxAge: FIFTEEN_MINUTES_IN_MILLISECONDS,
  exclude: {
    query: false,
  },
};
export const cache1hour = {
  ignoreCache: false,
  maxAge: ONE_HOUR_IN_MILLISECONDS,
  exclude: {
    query: false,
  },
};

export function clearCacheContaining(cache, substring) {
  const itemsToRemove = Object.keys(cache.store?.store).filter((k) => k.includes(substring)) || [];
  for (const itemToRemove of itemsToRemove) cache.store.removeItem(itemToRemove);
}

export function clearCacheEndingWith(cache, substring) {
  const itemsToRemove = Object.keys(cache.store?.store).filter((k) => _.endsWith(k, substring)) || [];
  for (const itemToRemove of itemsToRemove) cache.store.removeItem(itemToRemove);
}
