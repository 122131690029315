import React, { useCallback, useEffect, useState } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import ProfileRendererErrorBoundary from "./ProfileRendererErrorBoundary";
import i18nProfile from "./common/i18nProfile";
import styled, { StyleSheetManager, ThemeProvider } from "styled-components";
import { GlobalFontStyles } from "../theme/fonts";
import { Helmet } from "react-helmet";
import { Hyphenopoly } from "../components/Hyphenopoly";
import { EditorPreviewSync } from "./EditorPreviewSync";
import { isEmpty } from "lodash";

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;

  * {
    box-sizing: border-box;
  }
`;

function FramePortal(props) {
  const [iframe, setIframe] = useState(null);

  const [forcedState, updateState] = useState(null);
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (iframe) {
      const googleFonts = document.getElementById("googleFonts");
      const fontsElement = googleFonts.cloneNode(true);

      // add google fonts
      if (googleFonts && iframe.contentDocument.body) {
        iframe.contentDocument.body.appendChild(fontsElement);
      }
      // add css normalization
      if (iframe.contentDocument.body) {
        iframe.contentDocument.body.insertAdjacentHTML(
          "afterBegin",
          "<style>body {margin: 0; box-sizing: border-box; background-color: white;} body * {box-sizing: inherit;}</style>"
        );
        if (props.preview) {
          iframe.contentDocument.body.insertAdjacentHTML("afterBegin", "<style>html {cursor: pointer;}</style>");
        }
      }
      // Set correct language (mainly for hyphenation)
      if (iframe.contentDocument.body?.parentElement) {
        iframe.contentDocument.body.parentElement.lang = props.language;
      }
      iframe.lang = props.language;
    }
  }, [iframe, props.preview, props.language, forcedState]);

  return (
    <StyledIframe
      srcDoc="<!DOCTYPE html>"
      id="iframe"
      name="iframe"
      title="iframe"
      ref={setIframe}
      onLoad={forceUpdate}
      scrolling={props.preview ? "no" : "auto"}
    >
      {iframe?.contentDocument?.body &&
        ReactDOM.createPortal(
          <StyleSheetManager target={iframe?.contentDocument.head}>
            <>
              <GlobalFontStyles />
              {props.children}
            </>
          </StyleSheetManager>,
          iframe.contentDocument.body
        )}
    </StyledIframe>
  );
}

export function ProfileRenderer({
  renderTemplate,
  customTheme,
  profileContent,
  derivedContent,
  profileName,
  language,
  themeOverride = {},
  useIframe = false,
  preview = false,
  useOriginalThemeOnly = false,
  renderAsSmartProfile = false,
  renderForPdf = false,
  hyphenation = true,
}) {
  const [TopRenderComponent, setTopRenderComponent] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);
  const [theme, setTheme] = useState(null);

  const { t, i18n } = useTranslation("translation", { i18n: i18nProfile });

  useEffect(() => {
    i18n.changeLanguage(language).then();
  }, [i18n, language]);

  useEffect(() => {
    if (renderTemplate?.slug && renderTemplate?.topRenderComponent)
      setTopRenderComponent(
        (prev) => prev || loadable(() => import(`./${renderTemplate.slug}/${renderTemplate.topRenderComponent}`))
      );
  }, [renderTemplate]);

  useEffect(() => {
    if (renderTemplate?.slug && language && preview)
      import(`./${renderTemplate.slug}/preview/${language}.json`)
        .then((importedModule) => importedModule.default)
        .then(setPreviewContent);
  }, [renderTemplate?.slug, language, preview]);

  useEffect(() => {
    if (renderTemplate?.slug)
      import(`./${renderTemplate.slug}/theme.json`)
        .then((importedModule) => importedModule.default)
        .then(setTheme);
  }, [renderTemplate?.slug]);

  if (!TopRenderComponent) return null;
  if (!profileContent) return null;
  if (preview && !previewContent) return null;
  if (!theme) return null;

  const content = preview ? { ...previewContent, ...derivedContent } : { ...profileContent, ...derivedContent };

  const themeAggregate = { ...theme };
  if (!useOriginalThemeOnly) {
    if (!isEmpty(theme)) Object.assign(themeAggregate, customTheme);
    if (themeOverride) Object.assign(themeAggregate, themeOverride);
  }

  if (useIframe) {
    return (
      <ProfileRendererErrorBoundary>
        <React.Suspense fallback="">
          <FramePortal preview={preview} language={language}>
            <ThemeProvider theme={themeAggregate}>
              <EditorPreviewSync>
                <TopRenderComponent
                  {...content}
                  profileName={profileName}
                  t={t}
                  language={language}
                  fullHeight={useIframe && preview}
                  renderAsSmartProfile={renderAsSmartProfile}
                  renderForPdf={renderForPdf}
                />
              </EditorPreviewSync>
            </ThemeProvider>
          </FramePortal>
        </React.Suspense>
      </ProfileRendererErrorBoundary>
    );
  }

  return (
    <ProfileRendererErrorBoundary>
      <React.Suspense fallback="">
        {hyphenation && <Hyphenopoly />}
        <ThemeProvider theme={themeAggregate}>
          <Helmet htmlAttributes={{ lang: language }} />
          <TopRenderComponent
            {...content}
            t={t}
            language={language}
            profileName={profileName}
            renderAsSmartProfile={renderAsSmartProfile}
            renderForPdf={renderForPdf}
          />
        </ThemeProvider>
      </React.Suspense>
    </ProfileRendererErrorBoundary>
  );
}
