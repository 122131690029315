export type BadgeSlug = string;

export type Badge = {
  slug: BadgeSlug;
  name: string;
  description?: string;
  icon?: string;
};

export type BadgeShieldSlug = string;

export type BadgeShield = {
  slug: BadgeShieldSlug;
  name: string;
  description?: string;
  isCompetenceShield?: boolean;
  icon?: string;
  badges: Badge[];
};

export type UserBadges = { [K in BadgeShieldSlug]: BadgeSlug[] };

export const Proficiencies = ["fundamental-awareness", "novice", "intermediate", "advanced", "expert"] as const;
export type Proficiency = (typeof Proficiencies)[number];

export type Competence = {
  months?: number;
  proficiency?: Proficiency;
};

export type UserCompetences = { [K in BadgeSlug]: Competence };

export enum UserRole {
  admin = "admin",
  sales = "sales",
  consultant = "consultant",
  subcontractor = "subcontractor",
}

export type Scope = "users:all" | "users:admin" | "users:sales" | "users:consultants" | "users:subcontractors";
export type ScopesPerRole = {
  [key in UserRole]: Scope[];
};

export enum UserStatus {
  notInvited = "NOT_INVITED",
  pendingConfirmation = "PENDING_CONFIRMATION",
  active = "ACTIVE",
  deleted = "DELETED",
}

export type Language = "unknown" | "sv" | "no" | "fi" | "en" | "de";

export type MainProfiles = { [key in Language]?: string };

export type User = {
  slug: string;
  organizationSlug: string;
  name: string;
  roles: string[];
  badges: UserBadges;
  suggestedBadges: UserBadges;
  competences: UserCompetences;
  suggestedCompetences?: UserCompetences;
  userStatus: UserStatus;
  email: string;
  language?: string;
  phone?: string;
  title?: string;
  unit?: string;
  pictureUrl?: string | null;
  pictureDataUrl?: string | null;
  mainProfiles?: MainProfiles;
  dateOfBirth?: Date;
  linkedInProfileId?: string;
  sex?: string;
  address?: {
    streetName1?: string;
    streetName2?: string;
    zipCode?: string;
    city?: string;
    country?: string;
  };
  preferences?: {
    welcomeDismissed?: boolean;
    instructionsLastSeenAt?: Date;
    newsLastSeenAt?: Date;
  };
};
