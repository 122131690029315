import React, { Component } from "react";

export default class ProfileRendererErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // An error has occurred. Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/no-did-update-set-state
    if (this.state.hasError && prevProps !== this.props) this.setState({ hasError: false, error: null });
  }

  render() {
    if (this.state.hasError) {
      const error = this.state.error && this.state.error.message ? this.state.error.message : "";
      return (
        <div>
          <h2>Something went wrong</h2>
          <p>The profile could not be rendered from the input data.</p>
          <pre>{error}</pre>
        </div>
      );
    }
    return this.props.children;
  }
}
