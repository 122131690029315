import React from "react";
import { Button, Header, Icon } from "semantic-ui-react";
import styled from "styled-components";

const IconAccessible = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChildrenContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 10px;
`;

function GiveFeedbackCard({
  headingText,
  leftButtonText,
  rightButtonText,
  actionOneCallBack,
  actionTwoCallBack,
  leftIcon,
  rightIcon,
  children,
}) {
  return (
    <>
      <Header as="h4">{headingText}</Header>
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
      <ButtonContainer>
        {leftButtonText && actionOneCallBack && (
          <div>
            <Button circular icon labelPosition="left" onClick={() => actionOneCallBack()}>
              {leftIcon && (
                <Icon name={leftIcon.semanticIcon || undefined}>
                  <IconAccessible role="img" aria-label={leftIcon.label}>
                    {leftIcon.emoji}
                  </IconAccessible>
                </Icon>
              )}
              {leftButtonText}
            </Button>
          </div>
        )}
        {rightButtonText && actionTwoCallBack && (
          <div>
            <Button circular icon labelPosition="left" onClick={() => actionTwoCallBack()}>
              {leftIcon && (
                <Icon name={rightIcon.semanticIcon || undefined}>
                  <IconAccessible role="img" aria-label={rightIcon.label}>
                    {rightIcon.emoji}
                  </IconAccessible>
                </Icon>
              )}
              {rightButtonText}
            </Button>
          </div>
        )}
      </ButtonContainer>
    </>
  );
}

export default GiveFeedbackCard;
