import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { getUsername } from "../../services/auth";
import {
  addProfileAttachment,
  deleteProfile,
  duplicateProfile as psDuplicateProfile,
  getContentSchema,
  getProfile,
  removeProfileAttachment,
  updateProfile,
} from "../../services/profileService";
import { callWithSpinner, callWithSpinnerAndNotifyOnError } from "../../Util";
import { useTranslation } from "react-i18next";
import { useStoredState } from "../../hooks/useStoredState";
import { LAST_PROFILE_ZOOM_SCALE } from "../../services/storageService";
import { execute } from "../../util-ts";
import { useAuthenticatedUserContext } from "../../contexts/AuthenticatedUserContextProvider";
import { User } from "../../contexts/models";
import { TFunction } from "i18next";
import { AnyProfile } from "../../momang-common/models/__generated__";

const PROFILE_LOCK_DURATION = (60 * 60 + 5) * 1000; // One hour and five seconds in milliseconds

export type ProfileRenderCommonProps = {
  overrideLogotypeUrl?: string;
  organizationLogotypeUrl?: string;
  userPictureUrl?: string;
  user?: User;
  profileName?: string;
  t: TFunction;
  language?: string;
  fullHeight?: boolean;
  renderAsSmartProfile?: boolean;
  renderForPdf?: boolean;
};

type Profile = {
  id: string;
  userSlug: string;
  contentSchemaSlug: string;
  lockedForEditBy: string;
  relationshipData: any;
  hasAttachment: boolean;
  attachmentOnly: boolean;
  language?: string;
  content: AnyProfile;
};

type ProfileEditorUninitializedContextType = {
  activeProfile: undefined;
  loading: boolean;
};

type ProfileEditorInitializedContextType = {
  activeProfile: Profile;
  loading: boolean;
  deleting: boolean;
  updatingLock: boolean;
  isAllowedToEdit: boolean;
  username: string;
  editor: {
    isDirty: boolean;
    setDirty: (isDirty: boolean) => void;
    readOnly: boolean;
  };
  contentSchema: any;
  focusedEditorFieldId?: string;
  setFocusedEditorFieldId: (id: string) => void;
  highlightedPreviewFieldId?: string;
  setHighlightedPreviewFieldId: (id: string) => void;
  lockForEdit: () => void;
  unlockForEdit: () => void;
  deleteActiveProfile: () => Promise<void>;
  duplicateProfile: () => Promise<boolean>;
  addRelationshipData: (newRelationshipData: any) => void;
  addAttachment: (file: File) => Promise<void>;
  removeAttachment: () => Promise<void>;
  handleProfileChange: (changedProfile: Profile) => void;
  deferSave: () => void;
  restoreSave: () => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
};

type ProfileEditorContextType = ProfileEditorUninitializedContextType | ProfileEditorInitializedContextType;

const ProfileEditorContext = createContext<ProfileEditorContextType>({
  activeProfile: undefined,
  loading: false,
});

export function useEditorContext() {
  return useContext(ProfileEditorContext);
}

/**
 * This component manages the editor context.
 * It provides functions to update the context and make api calls.
 */
export function ProfileEditorContextProvider({ children }: { children: ReactNode }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [activeProfile, setActiveProfile] = useState<Profile>();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updatingLock, setUpdatingLock] = useState(false);
  const [username, setUsername] = useState("");
  const { authenticatedUser, isAdmin, isSales } = useAuthenticatedUserContext();
  const [editorIsDirty, setEditorIsDirty] = useState(false);
  const deferSave = useRef(false);
  const [contentSchema, setContentSchema] = useState();
  const [focusedEditorFieldId, setFocusedEditorFieldId] = useState<string>();
  const [highlightedPreviewFieldId, setHighlightedPreviewFieldId] = useState<string>();
  const [zoomScale, setZoomScale] = useStoredState<number>(LAST_PROFILE_ZOOM_SCALE, 1);

  const reloadTimer = useRef<number | null>(null);

  // Change history
  const [changeHistory, setChangeHistory] = useState<Profile[]>([]);
  const [historyPosition, setHistoryPosition] = useState<number>(0);

  // Handle history additions
  const addToHistory = useCallback(
    (profile: Profile) => {
      const newHistory = changeHistory;
      if (changeHistory.length >= 1000) {
        newHistory.pop();
      }
      newHistory.unshift(profile);
      setChangeHistory(newHistory);
    },
    [changeHistory]
  );

  // Handle resetting history starting point
  const setHistoryStartingPoint = useCallback(
    (index: number) => {
      if (changeHistory[index]) {
        const newHistory = changeHistory;
        newHistory.slice(index, changeHistory.length);
        setChangeHistory(newHistory);
      }
    },
    [changeHistory]
  );

  const loadAndInitialize = useCallback(() => {
    getUsername().then((u) => setUsername(u));
    callWithSpinner(
      () =>
        getProfile(params.profileId).then((p) => {
          setActiveProfile(p);
          setEditorIsDirty(false);
          setHistoryPosition(0);
          addToHistory(p);
        }),
      setLoading
    );
  }, [params.profileId, addToHistory]);

  const reloadTimerHeartbeat = useCallback(() => {
    if (reloadTimer.current) window.clearTimeout(reloadTimer.current);
    reloadTimer.current = window.setTimeout(loadAndInitialize, PROFILE_LOCK_DURATION);
  }, [loadAndInitialize]);

  // Initialize username and profile
  useEffect(() => {
    loadAndInitialize();
  }, [params.profileId, loadAndInitialize]);

  // Initialize content schema
  useEffect(() => {
    if (activeProfile?.contentSchemaSlug) {
      getContentSchema(activeProfile?.contentSchemaSlug).then((c) => setContentSchema(c));
    }
  }, [activeProfile?.contentSchemaSlug]);

  // Profile delete handler
  const deleteActiveProfile = useCallback(async () => {
    await callWithSpinnerAndNotifyOnError(async () => {
      if (activeProfile) {
        await deleteProfile(activeProfile.id);
        navigate(`/consultants/${activeProfile.userSlug}`);
      }
      return true;
    }, setDeleting);
  }, [activeProfile, navigate]);

  // Profile duplicate handler
  const duplicateProfile = useCallback(async () => {
    const createdProfile = await psDuplicateProfile(activeProfile, t);
    navigate(`/profiles/${createdProfile.id}`);
    return true;
  }, [activeProfile, navigate, t]);

  const saveProfile = useCallback(
    async (profile: Profile) => {
      if (deferSave.current) return;
      reloadTimerHeartbeat();
      await updateProfile(profile);
      setEditorIsDirty(false);
    },
    [reloadTimerHeartbeat]
  );

  const saveProfileDebounced = useMemo(() => _.debounce(saveProfile, 750, { maxWait: 5000 }), [saveProfile]);

  const addRelationshipData = useCallback((newRelationshipData: any) => {
    setActiveProfile((currentActiveProfile) => {
      if (!currentActiveProfile) return undefined;
      return {
        ...currentActiveProfile,
        relationshipData: { ...currentActiveProfile?.relationshipData, ...newRelationshipData },
      };
    });
  }, []);

  const addAttachment = useCallback(
    async (file: File) => {
      if (!activeProfile) return;
      await addProfileAttachment(activeProfile.id, file);
      setActiveProfile({ ...activeProfile, hasAttachment: true });
    },
    [activeProfile]
  );

  const removeAttachment = useCallback(async () => {
    if (!activeProfile) return;
    await removeProfileAttachment(activeProfile.id);
    setActiveProfile({ ...activeProfile, hasAttachment: false });
  }, [activeProfile]);

  // Lock profile handler
  const updateLock = useCallback(
    async (lockedForEditBy: string) => {
      if (!activeProfile) return;
      execute(
        async () => {
          reloadTimerHeartbeat();
          const profile = await updateProfile({ id: activeProfile.id, lockedForEditBy });
          setEditorIsDirty(false);
          setActiveProfile(profile);
        },
        { spinnerSetter: setUpdatingLock, notifyOnError: true }
      );
    },
    [activeProfile, reloadTimerHeartbeat]
  );

  // Lock profile handler
  const lockForEdit = useCallback(async () => {
    await updateLock(username);
  }, [username, updateLock]);

  // Unlock profile handler
  const unlockForEdit = useCallback(async () => {
    await saveProfileDebounced.flush();
    await updateLock("");
  }, [updateLock, saveProfileDebounced]);

  useEffect(
    () => () => {
      if (reloadTimer.current) window.clearTimeout(reloadTimer.current);
      if (saveProfileDebounced) saveProfileDebounced.cancel();
    },
    [saveProfileDebounced]
  );

  // Editor change handler
  const handleProfileChange = useCallback(
    (changedProfile: Profile) => {
      const newProfile = {
        ...changedProfile,
        hasContent: !_.isEmpty(
          _.flatMapDeep(_.omit(changedProfile.content as any, ["consultant"])).filter((value) => !_.isEmpty(value))
        ),
      };
      if (_.isEqual(newProfile, activeProfile)) return;
      setEditorIsDirty(true);
      addToHistory(newProfile);
      if (historyPosition > 0) {
        setHistoryStartingPoint(historyPosition);
        setHistoryPosition(0);
      }
      setActiveProfile(newProfile);
      saveProfileDebounced(newProfile);
    },
    [activeProfile, addToHistory, historyPosition, setHistoryStartingPoint, saveProfileDebounced]
  );

  const navigateInHistory = useCallback(
    (position: number) => {
      if (position < 0) return;
      const profile = changeHistory[position];
      if (profile) {
        setActiveProfile(profile);
        setEditorIsDirty(true);
        setHistoryPosition(position);
        saveProfileDebounced(profile);
      }
    },
    [changeHistory, saveProfileDebounced]
  );

  const editorIsReadOnly = useMemo(
    () => activeProfile?.lockedForEditBy !== username,
    [activeProfile?.lockedForEditBy, username]
  );
  const editor = useMemo(
    () => ({
      isDirty: editorIsDirty,
      setDirty: setEditorIsDirty,
      readOnly: editorIsReadOnly,
    }),
    [editorIsDirty, editorIsReadOnly]
  );

  const canUndo = useMemo(() => !!changeHistory[historyPosition + 1], [changeHistory, historyPosition]);
  const canRedo = useMemo(() => !!changeHistory[historyPosition - 1], [changeHistory, historyPosition]);

  const state = useMemo<ProfileEditorContextType>(() => {
    if (!activeProfile) return { loading: true, activeProfile: undefined };
    return {
      activeProfile,
      loading,
      deleting,
      updatingLock,
      isAllowedToEdit: activeProfile?.userSlug === authenticatedUser?.slug || isAdmin || isSales || false,
      username,
      editor,
      contentSchema,
      focusedEditorFieldId,
      setFocusedEditorFieldId,
      highlightedPreviewFieldId,
      setHighlightedPreviewFieldId,
      lockForEdit,
      unlockForEdit,
      deleteActiveProfile,
      duplicateProfile,
      addRelationshipData,
      addAttachment,
      removeAttachment,
      handleProfileChange,
      deferSave: () => {
        deferSave.current = true;
      },
      restoreSave: () => {
        deferSave.current = false;
        if (editor.isDirty && activeProfile) saveProfileDebounced(activeProfile);
      },
      undo: () => navigateInHistory(historyPosition + 1),
      redo: () => navigateInHistory(historyPosition - 1),
      canUndo,
      canRedo,
      zoomScale,
      setZoomScale,
    };
  }, [
    activeProfile,
    loading,
    deleting,
    updatingLock,
    username,
    editor,
    contentSchema,
    focusedEditorFieldId,
    highlightedPreviewFieldId,
    lockForEdit,
    unlockForEdit,
    deleteActiveProfile,
    duplicateProfile,
    addRelationshipData,
    addAttachment,
    removeAttachment,
    handleProfileChange,
    navigateInHistory,
    historyPosition,
    canUndo,
    canRedo,
    saveProfileDebounced,
    zoomScale,
    setZoomScale,
    authenticatedUser,
    isAdmin,
    isSales,
  ]);

  return <ProfileEditorContext.Provider value={state}>{children}</ProfileEditorContext.Provider>;
}
