import { extension } from "mime-types";
import { triggerDownloadFromUrl } from "../Util";
import axios from "axios";
import { binaryDownloadOptions } from "./momangAxiosClient";

let axiosClientPublic;

function initPublic() {
  if (axiosClientPublic) return;
  axiosClientPublic = axios.create({ baseURL: process.env.REACT_APP_PROFILE_BACKEND_ENDPOINT });
}

export async function getMomangMetadata() {
  initPublic();
  const result = await axiosClientPublic.get("/metadata");
  return result.data;
}

export async function getProfileFromShare(shareSlug, ndaAccepted = undefined) {
  await initPublic();
  const response = await axiosClientPublic.get(`/smart-profiles/shares/${shareSlug}/profile`, {
    params: { ndaAccepted },
  });
  return response.data;
}

export async function sendFeedback(shareSlug, consultantName, feedbackObject) {
  await initPublic();
  const response = await axiosClientPublic.post(`/smart-profiles/feedback/${shareSlug}`, {
    consultantName,
    ...feedbackObject,
  });
  return response.data;
}

/**
 * Fetches a specific profile PDF from a share link and triggers the browser save file handling.
 */
export async function downloadProfilePdfFromShare(shareSlug, downloadAsFileName = "profile") {
  await initPublic();
  const response = await axiosClientPublic.get(`smart-profiles/shares/${shareSlug}/profile/pdf`, binaryDownloadOptions);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  triggerDownloadFromUrl(url, `${downloadAsFileName}.pdf`);
}

/**
 * Fetches a profile attachment from a share link.
 */
export async function getProfileAttachmentFromShare(shareSlug) {
  await initPublic();
  const response = await axiosClientPublic.get(
    `smart-profiles/shares/${shareSlug}/profile/attachment`,
    binaryDownloadOptions
  );
  const ext = extension(response.headers["content-type"]);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  return [url, ext];
}

export async function getProfilePdfAttachmentFromShare(shareSlug) {
  const [url, ext] = await getProfileAttachmentFromShare(shareSlug);
  return ext === "pdf" ? url : null;
}

/**
 * Fetches a profile attachment from a share link and triggers the browser save file handling.
 */
export async function downloadProfileAttachmentFromShare(shareSlug, downloadAsFileName = "profile-attachment") {
  const [url, ext] = await getProfileAttachmentFromShare(shareSlug);
  triggerDownloadFromUrl(url, `${downloadAsFileName}.${ext}`);
}
