import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { startAuthSessionFromCode } from "../services/auth";
import { Auth } from "@aws-amplify/auth";
import { Container, Loader, Message, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import authenticationBackground from "../assets/images/authentication-background.jpg";
import styled from "styled-components";
import { getAuthenticatedUser } from "../services/profileService";

async function signInWithAuthorizationCode(code, navigate) {
  await startAuthSessionFromCode(code);
  try {
    await getAuthenticatedUser();
    navigate("/");
  } catch (e) {
    console.warn("signInWithAuthorizationCode: ", e, e.toJSON());
    if (e.toJSON().status === 410) navigate("/no-mans-land");
    else throw e;
  }
}

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background: #ffd2d5 url("${authenticationBackground}") no-repeat center;
  background-size: cover;
`;

export function AuthorizationCode() {
  const [t] = useTranslation();
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const navigate = useNavigate();
  const [alreadySignedIn, setAlreadySignedIn] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    Auth.currentSession()
      .then(() => navigate("/"))
      .catch(() => setAlreadySignedIn(false));
  }, [navigate]);

  useEffect(() => {
    if (!urlSearchParams.has("code")) setError(t("component.authorizationCode.codeMissing"));
  }, [t, urlSearchParams]);

  useEffect(() => {
    if (urlSearchParams.has("code") && alreadySignedIn === false)
      signInWithAuthorizationCode(urlSearchParams.get("code"), navigate).catch((e) =>
        setError(`${e.message}: ${JSON.stringify(e.response?.data)}`)
      );
  }, [search, alreadySignedIn, navigate, urlSearchParams]);

  return (
    <Background>
      <Container text>
        <Segment textAlign="center">
          <h1>{t("component.authorizationCode.heading")}</h1>
          <Loader active={!error} inline />
          <Message error hidden={!error}>
            <p>{t("component.authorizationCode.errorIntroduction")}</p>
            <code>{error}</code>
          </Message>
        </Segment>
      </Container>
    </Background>
  );
}
