import React, { createContext, useContext, useEffect, useState } from "react";
import Mousetrap from "mousetrap";

const KeyboardShortcutContext = createContext({});

export function useKeyboardShortcutContext() {
  return useContext(KeyboardShortcutContext);
}

export function KeyboardShortcutContextProvider({ children }) {
  const [showKeyboardShortcutLabels, setShowKeyboardShortcutLabels] = useState(false);

  useEffect(() => {
    // When the stopCallback function returns true, no keyboard shortcut is triggered, see https://craig.is/killing/mice.
    // The default implementation stops keyboard shortcuts when an input field is focused. Here, we always allow them.
    Mousetrap.prototype.stopCallback = (_e, _element, _combo) => false;

    Mousetrap.bind("ctrl", () => setShowKeyboardShortcutLabels(true), "keydown");
    Mousetrap.bind("ctrl", () => setShowKeyboardShortcutLabels(false), "keyup");

    return () => {
      Mousetrap.unbind("ctrl", "keydown");
      Mousetrap.unbind("ctrl", "keyup");
    };
  }, []);

  const value = {
    showKeyboardShortcutLabels,
  };
  return <KeyboardShortcutContext.Provider value={value}>{children}</KeyboardShortcutContext.Provider>;
}
