import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled, { css } from "styled-components";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./PdfPreview.less";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function Dimmer({ loading, outOfDate, loadingTextI18nKey, outOfDateTextI18nKey }) {
  const [t] = useTranslation();

  const hide = !loading && !outOfDate;
  const text = outOfDate ? t(outOfDateTextI18nKey) : t(loadingTextI18nKey);

  return (
    <div id="dimmer" className={hide ? "hide" : undefined}>
      {!hide && (
        <div className="info">
          {loading && (
            <div className="spinner">
              <Loader active inline inverted size="small" />
            </div>
          )}
          <div className="text">{text}</div>
        </div>
      )}
    </div>
  );
}

const a4ratio = 297 / 210;
const EmptyPage = styled.div.attrs({ className: "pdfPage" })`
  ${({ width = 600 }) => css`
    width: ${width}px;
    height: ${width * a4ratio}px;
  `}
`;

function calculatePdfWidth(element) {
  return element.clientWidth - 50;
}

export function PdfPreview({
  profileId,
  fetchPdfFn,
  isDirty = false,
  loadingTextI18nKey = "component.profilePdfPreview.generating",
  outOfDateTextI18nKey = "component.profilePdfPreview.outOfDate",
  zoomScale = 1,
}) {
  const [width, setWidth] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pdf, setPdf] = useState();
  const [loading, setLoading] = useState(true);
  const [outOfDate, setOutOfDate] = useState(isDirty);
  const scrollPosition = useRef(0);
  const containerRef = createRef();
  const runningFetch = useRef();

  useEffect(() => {
    // Introducing a state for the editorIsDirty prop handles the case where the dimmer would flicker briefly
    // after a profile has been saved, but we haven't yet started to update the PDF preview. #hackerman
    setOutOfDate(isDirty);
  }, [isDirty]);

  useEffect(() => {
    function fetchProfile() {
      setLoading(true);
      runningFetch.current = fetchPdfFn?.(profileId)
        .then(setPdf)
        .finally(() => {
          runningFetch.current = null;
        });
    }

    if (runningFetch.current) {
      runningFetch.current.cancel?.();
      setLoading(false);
    }
    if (!isDirty) fetchProfile();
  }, [profileId, isDirty, fetchPdfFn]);

  useEffect(() => {
    function resize() {
      const newWidth = calculatePdfWidth(containerRef.current);
      if (newWidth > 200) setWidth(newWidth);
    }

    setWidth(calculatePdfWidth(containerRef.current));
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [containerRef]);

  const updateScrollPosition = useCallback((event) => {
    if (!event) return;
    if (event.target?.scrollTop > 0) scrollPosition.current = event.target?.scrollTop;
  }, []);

  function onLoadSuccess(lodadedPdf) {
    setNumberOfPages(lodadedPdf.numPages);
  }

  function onLastPageRendered() {
    if (containerRef.current) containerRef.current.scrollTop = scrollPosition.current;
    setLoading(false);
  }

  const emptyPage = <EmptyPage width={width} />;

  const pages = [...Array(numberOfPages).keys()].map((n, i) => (
    <Page
      key={n}
      pageNumber={n + 1}
      width={width}
      renderTextLayer={false}
      onRenderSuccess={i === numberOfPages - 1 ? onLastPageRendered : null}
      className="pdfPage"
      loading={emptyPage}
      renderAnnotationLayer={false}
      scale={zoomScale}
    />
  ));

  return (
    <div ref={containerRef}>
      <div id="dimmableContainer" onScroll={updateScrollPosition}>
        <Dimmer
          loading={loading}
          outOfDate={outOfDate}
          loadingTextI18nKey={loadingTextI18nKey}
          outOfDateTextI18nKey={outOfDateTextI18nKey}
        />
        <div id="pdf-preview-div">
          <Document file={pdf} noData={emptyPage} loading={emptyPage} onLoadSuccess={onLoadSuccess}>
            {pages}
          </Document>
        </div>
      </div>
    </div>
  );
}
