import React from "react";
import { Icon, Menu } from "semantic-ui-react";

export function SmartFooter() {
  return (
    <Menu fixed="bottom" borderless>
      <Menu.Item as="p" position="right">
        Powered by&nbsp;
        <a href="https://www.momang.io" target="_blank" rel="noopener noreferrer">
          Momang
        </a>
        &nbsp;
        <Icon name="heart outline" />
      </Menu.Item>
    </Menu>
  );
}
