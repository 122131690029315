export const PAGE = 1127;
export const PAGE_02 = PAGE * 0.2;
export const PAGE_08 = PAGE * 0.8;

const sizePixels = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeMonitor: 1200,
  widescreenMonitor: 2560,
};

const size = {
  mobile: `${sizePixels.mobile}px`,
  tablet: `${sizePixels.tablet}px`,
  computer: `${sizePixels.computer}px`,
  largeMonitor: `${sizePixels.largeMonitor}px`,
  widescreenMonitor: `${sizePixels.widescreenMonitor}px`,
};

const breakpoint = {
  mobileS: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  computer: `(min-width: ${size.computer})`,
  largeMonitor: `(min-width: ${size.largeMonitor})`,
  widescreenMonitor: `(min-width: ${size.widescreenMonitor})`,
};

const width = {
  page: `${PAGE}px`,
  page02: `${PAGE_02}px`,
  page08: `${PAGE_08}px`,
};

const color = {
  primary: {
    // blue
    base: "#1d299a",
    opaque: {
      light: "rgba(0, 133, 255, 0.1)",
    },
  },
  accent1: {
    // blue
    base: "#1d299a",
    opaque: {
      light: "rgba(0, 133, 255, 0.1)",
    },
  },
  accent2: {
    // orange
    base: "#f37328",
  },
  secondary1: {
    // beige
    base: "#f4ede9",
  },
  secondary2: {
    // pink
    base: "#ffd2d5",
  },
  secondary3: {
    // light blue
    base: "#b0d8f4",
  },
  extra1: {
    // dark blue
    base: "#16294d",
  },
  extra2: {
    // green
    base: "#41b196",
  },
  light1: {
    base: "#c3c8f1",
  },
  light2: {
    base: "#eef0ff",
  },
  greyscale: {
    light: "#E2E2E2",
    opaque: {
      light: "rgba(226, 226, 226, 0.6)",
      medium: "rgba(200, 200, 200, 0.8)",
      dark: "rgba(18, 18, 18,  0.6)",
    },
  },
  red: {
    base: "#9F3A38",
    light: "#FFF6F6",
  },
};

const border = {
  radius: {
    default: "4px",
    large: "8px",
  },
  thinGreyscaleLight: `1px solid ${color.greyscale.light}`,
  thinSecondary: `1px solid ${color.primary.base}`,
  thinPrimary: `1px solid ${color.light1.base}`,
};

export const theme = {
  border,
  color,
  width,
  sizePixels,
  size,
  breakpoint,
};

export const topBarHeightNumber = 46;
export const topBarHeight = `${topBarHeightNumber}px`;
