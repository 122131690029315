import { ERROR, notifyUser } from "./services/notificationService";
import { joinStrings } from "./render-templates/Utils";
import { CancelablePromise } from "cancelable-promise";
import { Dispatch, SetStateAction } from "react";
import _ from "lodash";

/**
 * Executes an async action and handles the spinner and error notification. Replaces non-typescript util functions
 * `notifyOnError`, `callWithSpinner`, `callWithSpinnerAndNotifyOnError`.
 */
export function execute<T>(
  action: () => Promise<T>,
  options?: {
    spinnerSetter?: ((value: boolean) => void) | Dispatch<SetStateAction<boolean>>;
    notifyOnError?: boolean;
    customErrorTitle?: string;
    customErrorMessage?: string;
  }
): CancelablePromise<T> {
  return new CancelablePromise(async (resolve, reject) => {
    try {
      options?.spinnerSetter?.(true);
      resolve(await action());
    } catch (error: any) {
      if (options?.notifyOnError)
        notifyUser(options?.customErrorTitle || "Oh, no!", options?.customErrorMessage || "Something went wrong.", {
          type: ERROR,
          technicalMessage: joinStrings([error.toString(), error.response?.request?.responseURL], "\n"),
          ctaTitle: undefined,
          onCta: undefined,
        });
      reject(error);
    } finally {
      options?.spinnerSetter?.(false);
    }
  });
}

export function emptyPromise() {
  return new Promise(() => {});
}

/**
 * Converts an editor field id to a lodash path. The id contains the path to the field, but in a format
 * that is not compatible with lodash.get.
 *
 * @param {string} editorFieldId - The editor field id that needs to be converted.
 * @return {string} - The converted lodash path.
 */
export function convertRjsfFieldIdToLodashPath(editorFieldId: string) {
  return editorFieldId
    .replace(/root/, "content")
    .replace(/_(\d+)(.*)/g, "[$1]$2")
    .replace(/_/g, ".");
}

/**
 * Converts an editor field id with an "-automator" suffix to a lodash path.
 * The id contains the path to the field, but in a format that is not compatible with lodash.get.
 * "content.projects[0].description-automator" -> "content.projects[0]"
 *
 * @param {string} editorFieldId - The editor field id that needs to be converted.
 * @return {string} - The converted lodash path without the "-automator" suffix.
 */
export function convertRjsfFieldAutomatorIdToLodashPath(editorFieldId: string) {
  return convertRjsfFieldIdToLodashPath(editorFieldId).replace(/\.[^.]+-automator$/, "");
}

type SuspendStatus = "pending" | "success" | "error";

/**
 * Suspends a promise so that it can be used in a React Suspense component.
 *
 * ```
 * import { getAsyncData} from "./some-service";
 *
 * const resource = suspend(getAsyncData());
 *
 * export function SomeComponent() {
 *   const [someState, setSomeState] = useState(resource.read());
 * ...
 * }
 * ```
 * @param promise
 */
export function suspend(promise: Promise<any>) {
  let status: SuspendStatus = "pending";
  let response: any;

  const suspender = promise.then(
    (res) => {
      status = "success";
      response = res;
    },
    (err) => {
      status = "error";
      response = err;
    }
  );

  function read() {
    switch (status) {
      case "pending":
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw suspender;
      case "error":
        if (process.env.REACT_APP_STAGE === "development") {
          console.error(response);
          return response;
        }
        throw response;
      default:
        return response;
    }
  }

  return { read };
}

export function trimLinkedInId(linkedInProfileId: string) {
  return _.last(linkedInProfileId.split("https://www.linkedin.com/in/"))?.replace("/", "");
}
