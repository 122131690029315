import React, { useCallback, useState } from "react";
import { Button, Confirm, Icon, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function getIcon(icon, postConfirmIcon, loading, confirmed, iconColor) {
  if (loading) return null;
  if (postConfirmIcon && confirmed) return postConfirmIcon;
  return typeof icon === "string" ? <Icon name={icon} color={iconColor} /> : icon;
}

function getText(text, postConfirmText, confirmed) {
  return postConfirmText && confirmed ? postConfirmText : text;
}

export default function ButtonWithTooltip({
  loadingOnClick = false,
  disableOnClick = false,
  onClick,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  icon,
  iconColor = "",
  buttonColor = "",
  text = "",
  confirm = false,
  confirmContent = "",
  confirmButton = "",
  postConfirmIcon = "",
  postConfirmText = "",
  compact = true,
  basic = true,
  size = "mini",
  loading = false,
  disabled = false,
  ...props
}) {
  const [t] = useTranslation();

  const [confirmed, setConfirmed] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [disabledInternal, setDisabledInternal] = useState(false);

  const onConfirm = useCallback(
    async (event, data) => {
      setConfirmed(true);
      setDialogOpen(false);
      if (loadingOnClick) setLoadingInternal(true);
      if (disableOnClick) setDisabledInternal(true);
      await onClick?.(event, data);
      if (loadingOnClick) setLoadingInternal(false);
      if (disableOnClick) setDisabledInternal(false);
    },
    [loadingOnClick, disableOnClick, onClick]
  );

  const onClickInternal = useCallback(
    async (event, data) => {
      if (confirm) setDialogOpen(confirm);
      else await onConfirm(event, data);
    },
    [confirm, onConfirm]
  );

  const onCancel = useCallback(async () => {
    setDialogOpen(false);
  }, []);

  const iconInternal = getIcon(icon, postConfirmIcon, loading, confirmed, iconColor);
  const textInternal = getText(text, postConfirmText, confirmed);

  return (
    <>
      <Popup
        content={textInternal}
        disabled={!textInternal}
        mouseEnterDelay={500}
        trigger={
          <Button
            size={size}
            onClick={onClickInternal}
            basic={basic}
            compact={compact}
            color={buttonColor}
            icon={iconInternal}
            loading={loading || loadingInternal}
            disabled={disabled || disabledInternal}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...props}
          />
        }
      />
      <Confirm
        open={dialogOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelButton={t("common.cancel")}
        confirmButton={confirmButton || t("common.ok")}
        header={t("common.confirmation")}
        content={confirmContent}
      />
    </>
  );
}
