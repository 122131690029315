import slugify from "slugify";

const INFINITY_STRING = "-infinity-";

export const DASHBOARD_LEADS_GROUP_BY = "dashboard-leads-group-by";
export const EDITOR_LAST_PREVIEW_TAB = "editor-last-preview-tab";
export const NDA_ACCEPTED = "nda-accepted";
export const LAST_SEEN_MOMANG_VERSION = "last-seen-momang-version";
export const LAST_SEARCH_QUERY = "last-search-query";
export const LAST_OPPORTUNITY_SEARCH_QUERY = "last-opportunity-search-query";
export const LAST_CLIENT_CONSULTANT_LIST_FILTER = "last-client-consultant-list-filter";
export const LAST_CLIENT_SEARCH_QUERY = "last-client-search-query";
export const LAST_CLIENT_SORT_COLUMN = "last-client-sort-column";
export const LAST_CLIENT_SORT_ORDER = "last-client-sort-order";
export const LAST_CLIENT_SELECTED_PAGE = "last-client-selected-page";
export const LAST_CLIENT_ITEMS_PER_PAGE = "last-client-items-per-page";
export const LAST_SHOW_ALLOCATION_EVENTS = "last-show-allocation-events";
export const LAST_TIME_WINDOW_SIZE = "last-time-window-size";
export const LAST_TIME_WINDOW_RESOLUTION = "last-time-window-resolution";
export const LAST_VISIBLE_RANGE = "last-visible-range";
export const LAST_ACCOUNT_MANAGER_USER_SLUG_FILTER = "last-account-manager-user-slug-filter";
export const LAST_LEADS_STATE_FILTER = "last-leads-state-filter";
export const LAST_LEADS_UNIT_FILTER = "last-leads-unit-filter";
export const LAST_AVAILABILITY_ORDER_BY = "last-availability-order-by";
export const LAST_AVAILABILITY_QUERY = "last-availability-query";
export const CONSULTANT_WELLNESS_TIME_SELECTION_FILTER = "consultant-wellness-time-selection-filter";
export const TEAM_WELLNESS_TIME_SELECTION_FILTER = "team-wellness-time-selection-filter";
export const LAST_CONSULTANT_PAGE_TAB_INDEX = "last-consultant-page-tab-index";
export const UNSAVED_NOTE = "unsaved-note";
export const LAST_ANALYTICS_DATE_RANGE = "last-analytics-date-range";
export const LAST_ANALYTICS_COMPETENCE_SHIELD_SLUG = "last-analytics-competence-shield-slug";
export const LAST_ANALYTICS_COMPETENCE_UNIT = "last-analytics-competence-unit";
export const LAST_ANALYTICS_COMPETENCE_ORDER_BY = "last-analytics-competence-order-by";
export const LAST_ANALYTICS_COMPETENCE_ORDER_BY_DESC = "last-analytics-competence-order-by-desc";
export const LAST_ANALYTICS_COMPETENCE_USER_ROLES = "last-analytics-competence-user-roles";
export const LAST_PROFILE_ZOOM_SCALE = "last-profile-zoom-scale";
export const LEADS_LIST_VIEW = "leads-list-view";

function calcKey(key, context) {
  slugify.extend({ "/": "-" });
  return `momang-${key}-${slugify(context || "")}`;
}

// Serializes an object to a string to be put in localstorage. Specifically handles the infinity value.
// Exported for testability.
export function serializeInternal(objectValue) {
  return JSON.stringify(objectValue, (key, value) => {
    if (value === Infinity) return INFINITY_STRING;
    return value;
  });
}

// Deserializes value stored in localstorage. Specifically, it handles infinity value and dates properly.
// Exported for testability.
export function deserializeInternal(stringValue) {
  try {
    return JSON.parse(stringValue, (key, value) => {
      if (typeof value === "string") {
        if (value === INFINITY_STRING) return Infinity;
        const potentialDate = new Date(value);
        if (JSON.stringify(potentialDate) === `"${value}"`) return potentialDate;
      }
      return value;
    });
  } catch (_e) {
    // Handles the case where we have a value stored by the previous version where strings were not stored with quotes
    // and therefore is not parsable with JSON.parse().
    if (stringValue === INFINITY_STRING) return Infinity;
    return stringValue;
  }
}

export function localSet(key, value, context) {
  const calculatedKey = calcKey(key, context);
  try {
    if (value === undefined) localStorage.removeItem(calculatedKey);
    else localStorage.setItem(calculatedKey, serializeInternal(value));
  } catch (error) {
    console.error("Could not set value in localstorage.", error, key, value, context);
  }
}

export function localGet(key, defaultValue, context) {
  try {
    const stringValue = localStorage.getItem(calcKey(key, context));
    if (stringValue === null) return defaultValue;
    return deserializeInternal(stringValue);
  } catch (error) {
    console.error("Could not get value from localstorage.", error, key, context);
  }
  return defaultValue;
}

export function localStatistics() {
  let numberOfItems = 0;
  let totalBytes = 0;
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("momang")) {
      numberOfItems += 1;
      totalBytes += (key.length + localStorage.getItem(key).length) * 2; // Two bytes per character
    }
  });
  return { numberOfItems, totalBytes };
}

export function localDeleteAllMomang() {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("momang")) localStorage.removeItem(key);
  });
}

export function localDeleteAll() {
  localStorage.clear();
}

export function cookiesDeleteAll() {
  const cookieNames = document.cookie.split(";").map((c) => c.trim().split("=")[0]);
  for (const cookieName of cookieNames) document.cookie = `${cookieName}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}
