import ReactGA from "react-ga4";

export function gaEvent(category, action) {
  ReactGA.event({ category, action });
  return true;
}

export const gaCrmAddOrganization = () => gaEvent("crm", "Clicked add new organization");

export const gaSetUserRoles = (roles) => {
  ReactGA.gtag("set", "user_properties", {
    roles,
  });
};
