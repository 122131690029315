import React, { useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { DatePicker } from "./datepicker/DatePicker";
import { useTranslation } from "react-i18next";
import { Button, Form, Popup, Segment } from "semantic-ui-react";
import GiveFeedbackCard from "./GiveFeedbackCard";
import { SimpleFormValidateField } from "./SimpleFormValidateField";
import { sendFeedback } from "../services/profileServicePublic";

const FeedbackContainer = styled.div`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  position: ${(props) => (props.sticky ? "sticky" : "relative")};
  position: ${(props) => (props.sticky ? "-webkit-sticky" : "relative")}; // Required for Safari
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
`;

const ButtonContainer = styled.div`
  margin: 4px;
  display: flex;
  z-index: 1;
  justify-content: ${(props) => (props.spaceBetween ? "space-between" : "flex-end")};
`;

export function GiveFeedback({
  shareSlug,
  profileOrganizationSlug,
  isSticky,
  isVisible,
  setIsVisible,
  consultantName,
  profileId,
}) {
  const [t] = useTranslation();
  const [activePage, setActivePage] = useState("feedbackPage");
  const [emailInput, setEmailInput] = useState("");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [validEmail, setValidaEmail] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [clickedInteresting, setClickedInteresting] = useState(false);
  const [emailAdress, setEmailAdress] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [name, setName] = useState("");
  const [proposedMeetingAt, setProposedMeetingAt] = useState(
    moment().add(1, "day").startOf("day").add(9, "hours").toDate()
  );
  const proposedMeetingIsChosen = useRef(false);

  const parentPages = {
    feedbackPage: null,
    bookMeeting: "feedbackPage",
    datePicker: "bookMeeting",
    howToGetContacted: "datePicker",
    emailForm: "howToGetContacted",
    mobileForm: "howToGetContacted",
    giveFeedback: "feedbackPage",
    thankU: "giveFeedback",
  };

  const changePage = (nextPage) => {
    setActivePage(nextPage);
  };

  const changePageAndHide = (nextPage, waitToClose) => {
    setActivePage(nextPage);
    if (waitToClose) setTimeout(() => setIsVisible(false), 3000);
    else setIsVisible(false);
  };

  const goToPreviousPage = () => {
    const prevPage = parentPages[activePage];
    setActivePage(prevPage);
  };

  const postFeedback = () => {
    const feedbackData = {
      phoneNumber,
      emailAdress,
      clickedInteresting,
      feedbackMessage,
      organizationSlug: profileOrganizationSlug,
      name,
      profileId,
      proposedMeetingAt: proposedMeetingIsChosen.current ? proposedMeetingAt : undefined,
    };
    sendFeedback(shareSlug, consultantName, feedbackData).then();
  };

  const handleSubmit = (nextPage) => {
    postFeedback();
    changePageAndHide(nextPage, true);
  };

  const handleClose = () => {
    if (clickedInteresting || feedbackMessage !== "") postFeedback();
    changePageAndHide("", false);
  };

  const itsInteresting = () => {
    setClickedInteresting(true);
    changePage("bookMeeting");
  };

  const isPhoneNumberOk = (number) => /^\d+$/.test(number);

  const isEmailOk = (email) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const bookMeeting = (
    <GiveFeedbackCard
      headingText={t("page.smartProfile.bookMeetingQuestion")}
      leftButtonText={t("page.smartProfile.yesTitle")}
      leftIcon={{ label: "happy face", emoji: "😄" }}
      rightButtonText={t("page.smartProfile.noTitle")}
      rightIcon={{ label: "hand doing stop sign", emoji: "✋" }}
      actionOneCallBack={() => changePage("datePicker")}
      actionTwoCallBack={() => handleSubmit("thankU")}
    />
  );

  const datePicker = (
    <GiveFeedbackCard
      headingText={t("common.pickDate")}
      leftButtonText={t("common.next")}
      leftIcon={{ label: "pointing hand", emoji: "👉" }}
      actionOneCallBack={() => {
        proposedMeetingIsChosen.current = true;
        changePage("howToGetContacted");
      }}
      children={
        <>
          <DatePicker
            value={proposedMeetingAt}
            clearable={false}
            onSelect={(newDate) => setProposedMeetingAt(newDate)}
            preventOverflow
          />
          <DatePicker
            value={proposedMeetingAt}
            placeholder="HH:mm"
            format="HH:mm"
            clearable={false}
            onSelect={(newTime) => setProposedMeetingAt(newTime)}
            preventOverflow
          />
        </>
      }
    />
  );

  const feedbackPage = (
    <GiveFeedbackCard
      headingText={t("page.smartProfile.whatDoYouThinkAboutTheProfile")}
      leftButtonText={t("page.smartProfile.interesting")}
      leftIcon={{ label: "star-eyes", emoji: "🤩" }}
      rightButtonText={t("page.smartProfile.leaveFeedback")}
      rightIcon={{ label: "talking bubble", emoji: "💬" }}
      actionOneCallBack={() => itsInteresting()}
      actionTwoCallBack={() => changePage("giveFeedback")}
    />
  );

  const howToGetContacted = (
    <GiveFeedbackCard
      headingText={t("page.smartProfile.howToContact")}
      leftButtonText={t("page.smartProfile.telephone")}
      leftIcon={{ label: "hand doing stop sign", semanticIcon: "phone" }}
      rightButtonText={t("common.email")}
      rightIcon={{ label: "hand doing stop sign", semanticIcon: "mail" }}
      actionOneCallBack={() => changePage("mobileForm")}
      actionTwoCallBack={() => changePage("emailForm")}
    />
  );

  const mobileForm = (
    <SimpleFormValidateField
      handleSubmit={() => {
        handleSubmit("thankU");
      }}
      headingText={t("page.smartProfile.contactDetails")}
      controlledInputLabelText={t("common.phoneNumber")}
      handleControlledInputOnChange={(d) => {
        setPhoneNumberInput(d.value);
        setValidPhoneNumber(isPhoneNumberOk(d.value));
        setPhoneNumber((isPhoneNumberOk(d.value) && d.value) || undefined);
      }}
      controlledValue={phoneNumberInput}
      isNotValidMessage={t("page.smartProfile.phoneNumberWarning")}
      isValid={validPhoneNumber}
      submitHeading={t("common.submit")}
      secondInputLabelText={t("common.name")}
      handleSecondInputOnChange={(d) => {
        setName(d.value);
      }}
      secondInputValue={name}
    />
  );

  const emailForm = (
    <SimpleFormValidateField
      handleSubmit={() => {
        handleSubmit("thankU");
      }}
      headingText={t("page.smartProfile.contactDetails")}
      controlledInputLabelText={t("common.email")}
      handleControlledInputOnChange={(d) => {
        setEmailInput(d.value);
        setValidaEmail(isEmailOk(d.value));
        setEmailAdress((isEmailOk(d.value) && d.value) || undefined);
      }}
      controlledValue={emailInput}
      isNotValidMessage={t("page.smartProfile.emailAdressWarning")}
      isValid={validEmail}
      submitHeading={t("common.submit")}
      secondInputLabelText={t("common.name")}
      handleSecondInputOnChange={(d) => {
        setName(d.value);
      }}
      secondInputValue={name}
    />
  );

  const giveFeedback = (
    <Form onSubmit={() => handleSubmit("thankU")}>
      <Form.TextArea
        required
        label={t("page.smartProfile.tellUsMore")}
        placeholder={t("page.smartProfile.tellUsMore")}
        value={feedbackMessage}
        onChange={(_, d) => setFeedbackMessage(d.value)}
      />
      <Form.Input
        label={t("page.smartProfile.name")}
        placeholder={t("page.smartProfile.name")}
        value={name}
        onChange={(_, d) => setName(d.value)}
      />
      <Button disabled={!feedbackMessage} circular type="submit" content={t("common.submit")} />
    </Form>
  );

  const thankU = <h3>{t("common.thankYou")}</h3>;

  const pages = {
    feedbackPage,
    bookMeeting,
    howToGetContacted,
    emailForm,
    mobileForm,
    giveFeedback,
    thankU,
    datePicker,
  };

  return (
    <FeedbackContainer sticky={isSticky} isVisible={isVisible}>
      <ButtonContainer spaceBetween={activePage !== "feedbackPage" && activePage !== "thankU"}>
        {activePage !== "feedbackPage" && activePage !== "thankU" && (
          <Button circular basic onClick={() => goToPreviousPage()} floated="left" icon="left arrow" />
        )}
        <Popup
          content={t("common.close")}
          position="bottom right"
          trigger={<Button circular basic onClick={() => handleClose()} icon="cancel" />}
        />
      </ButtonContainer>
      <Segment style={{ border: "none", boxShadow: "none", marginTop: -35 }} textAlign="center">
        {pages[activePage]}
      </Segment>
    </FeedbackContainer>
  );
}
