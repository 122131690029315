import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { localGet, localSet } from "../services/storageService";
import _ from "lodash";

/**
 * This hooks behaves like useState, but it also stores and retrieves the state in localStorage.
 */
export function useStoredState<T>(
  key: string,
  initialValue: T,
  options?: { storePerPath: boolean }
): [T, (newState: T) => void] {
  const { pathname } = useLocation();
  const [state, setState] = useState<T>(initialValue);

  if (key && _.isEqual(state, initialValue)) {
    const newState = localGet(key, initialValue, options?.storePerPath ? pathname : undefined);
    if (!_.isEqual(state, newState)) setState(newState);
  }

  const setStoredState = useCallback(
    (newState: T) => {
      setState(newState);
      localSet(key, newState, options?.storePerPath ? pathname : undefined);
    },
    [key, options, pathname]
  );

  return [state, setStoredState];
}
