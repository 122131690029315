import { createGlobalStyle } from "styled-components";

export const GlobalFontStyles = createGlobalStyle`
    /* Helvetica Neue */
    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Ultra-Light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Ultra-Light-Italic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Thin.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Thin-Italic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Light.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Light-Italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Roman.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Italic.woff2") format("woff2");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Medium.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Medium-Italic.woff2") format("woff2");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Bold-Italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Heavy.woff2") format("woff2");
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Heavy-Italic.woff2") format("woff2");
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Neue";
        src: url("/fonts/HelveticaNeue/HelveticaNeue-Black-Italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    /* Avenir LT 55*/

    @font-face {
        font-family: "Avenir LT 55";
        src: url("/fonts/avenir-lt/avenir-LT-55-roman-regular.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    /* Avenir LT 35 */

    @font-face {
        font-family: "Avenir LT 35";
        src: url("/fonts/avenir-lt/avenir-LT-std-35-light.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    /* Avenir */

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-light-oblique.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-medium.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-medium-oblique.woff2") format("woff2");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-heavy.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-heavy-oblique.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Avenir";
        src: url("/fonts/avenir/avenir-black-oblique.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    /* Helvetica Now Display */
    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-extrabold-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-extrabold.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-light-italic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-medium-italic.woff2") format("woff2");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-medium.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-regular-italic.woff2") format("woff2");
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: "Helvetica Now Display";
        src: url("/fonts/helvetica-now-display/helvetica-now-display-regular.woff2") format("woff2");
        font-weight: normal;
        font-style: normal;
    }

    /* TT Norms */
    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-extrabold-italic.woff2") format("woff2");
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-extrabold.woff2") format("woff2");
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-extralight-italic.woff2") format("woff2");
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-extralight.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-heavy-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-heavy.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-light-italic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-medium-italic.woff2") format("woff2");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-thin-italic.woff2") format("woff2");
        font-weight: 100;
        font-style: italic;
    }

    @font-face {
        font-family: "TT Norms";
        src: url("/fonts/tt-norms/tt-norms-thin.woff2") format("woff2");
        font-weight: 100;
        font-style: normal;
    }


    /* Playfair Display */

    @font-face {
        font-family: "Playfair Display";
        src: url("/fonts/playfair-display/playfair-display-black-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "Playfair Display";
        src: url("/fonts/playfair-display/playfair-display-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Playfair Display";
        src: url("/fonts/playfair-display/playfair-display-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Playfair Display";
        src: url("/fonts/playfair-display/playfair-display-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Playfair Display";
        src: url("/fonts/playfair-display/playfair-display-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Playfair Display";
        src: url("/fonts/playfair-display/playfair-display-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }


    /* Gotham */

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-bold-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-bold.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-book-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-book.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-light-italic.woff2") format("woff2");
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-light.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-medium-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Gotham";
        src: src("/fonts/gotham/gotham-medium.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }


    /* Myriad Pro*/

    @font-face {
        font-family: "Myriad Pro";
        src: url("/fonts/myriad-pro/myriad-pro-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Myriad Pro";
        src: url("/fonts/myriad-pro/myriad-pro-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Myriad Pro";
        src: url("/fonts/myriad-pro/myriad-pro-light.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Myriad Pro";
        src: url("/fonts/myriad-pro/myriad-pro-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }


    /* Product Sans */

    @font-face {
        font-family: "Product Sans";
        src: url("/fonts/product-sans/product-sans-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Product Sans";
        src: url("/fonts/product-sans/product-sans-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Product Sans";
        src: url("/fonts/product-sans/product-sans-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Product Sans";
        src: url("/fonts/product-sans/product-sans-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }


    /* Montserrat */

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-mediumitalic.woff2") format("woff2");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-semibold.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-semibolditalic.woff2") format("woff2");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-bolditalic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-extrabold.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-extrabolditalic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-lightitalic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-extralight.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-extralightitalic.woff2") format("woff2");
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-thin.woff2") format("woff2");
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/montserrat/montserrat-thinitalic.woff2") format("woff2");
        font-weight: 100;
        font-style: italic;
    }


    /* Renner */

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-100-hairline-italic.woff2") format("woff2");
        font-weight: 100;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-100-hairline.woff2") format("woff2");
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-200-thin-italic.woff2") format("woff2");
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-200-thin.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-300-light-italic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-300-light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-400-book-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-400-book.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-500-medium-italic.woff2") format("woff2");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-500-medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-600-semi-italic.woff2") format("woff2");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-600-semi.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-700-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-700-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-800-heavy-italic.woff2") format("woff2");
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-800-heavy.woff2") format("woff2");
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-900-black-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "Renner";
        src: url("/fonts/renner/renner-900-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    /* Universal Sans */

    // Generated from https://universalsans.com/1/index.html#v1.2.20.0.0.221112212112.01.550
    @font-face {
        font-family: "Universal Sans";
        src: url("/fonts/universal-sans/universal-sans-v1-2-20-0-0-221112212112-01-550.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    /* DejaVu Sans */

    @font-face {
        font-family: "DejaVu Sans";
        src: url("/fonts/dejavu-sans/dejavu-sans-book.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "DejaVu Sans";
        src: url("/fonts/dejavu-sans/dejavu-sans-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "DejaVu Sans";
        src: url("/fonts/dejavu-sans/dejavu-sans-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "DejaVu Sans";
        src: url("/fonts/dejavu-sans/dejavu-sans-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "DejaVu Sans";
        src: url("/fonts/dejavu-sans/dejavu-sans-extralight.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    /* Berlin Sans */
    @font-face {
        font-family: "Berlin Sans FB";
        src: url("/fonts/berlin-sans-fb/berlin-sans-fb-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Berlin Sans FB";
        src: url("/fonts/berlin-sans-fb/berlin-sans-fb-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    /* Catamaran */
    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-thin.woff2") format("woff2");
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-extralight.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-semibold.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-extrabold.woff2") format("woff2");
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: "Catamaran";
        src: url("/fonts/catamaran/catamaran-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    /* Garnett */

    @font-face {
        font-family: "Garnett";
        src: url("/fonts/garnett/garnett-light.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Garnett";
        src: url("/fonts/garnett/garnett-medium.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Garnett";
        src: url("/fonts/garnett/garnett-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Garnett";
        src: url("/fonts/garnett/garnett-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    /* Graebenbach Mono */

    @font-face {
        font-family: "Graebenbach Mono";
        src: url("/fonts/graebenbach-mono/graebenbach-mono-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Graebenbach Mono";
        src: url("/fonts/graebenbach-mono/graebenbach-mono-medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Graebenbach Mono";
        src: url("/fonts/graebenbach-mono/graebenbach-mono-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    /* Calibri */
    @font-face {
        font-family: "Calibri";
        src: url("/fonts/calibri/calibri.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Calibri";
        src: url("/fonts/calibri/calibri-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Calibri";
        src: url("/fonts/calibri/calibri-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Calibri";
        src: url("/fonts/calibri/calibri-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    /* Gill Sans */

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-light.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-heavy.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-light-italic.woff2") format("woff2");
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-medium-italic.woff2") format("woff2");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Gill Sans";
        src: url("/fonts/gill-sans/gill-sans-heavy-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    /* Segoe UI */

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-black-italic.woff2") format("woff2");
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-black.woff2") format("woff2");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-bold-italic.woff2") format("woff2");
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-italic.woff2") format("woff2");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-light-italic.woff2") format("woff2");
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-light.woff2") format("woff2");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-semibold-italic.woff2") format("woff2");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-semibold.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-semilight-italic.woff2") format("woff2");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Segoe UI";
        src: url("/fonts/segoe-ui/segoe-ui-semilight.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    /* Zilla Slab */

     @font-face {
        font-family: "Zilla Slab";
        src: url("/fonts/zilla-slab/zilla-slab-regular.ttf") format("truetype");
    }

    /* Public Sans */

     @font-face {
        font-family: "Public Sans";
        src: url("/fonts/public-sans/public-sans-variable.ttf") format("truetype");
    }
`;
