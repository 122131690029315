import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/fi";
import "moment/locale/nb";
import "moment/locale/de";
import packageJson from "../package.json";
import { startsWith } from "lodash";

const supportedLanguages = ["en", "sv"];

function isSupportedLanguage(lng: string) {
  return supportedLanguages.some((supportedLanguage) => startsWith(lng, supportedLanguage));
}

let isInitialized = false;
if (!isInitialized) {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: "en",
      // debug: true,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      backend: {
        queryStringParams: {
          v: packageJson.version,
        },
      },
    });
  i18n.on("languageChanged", (lng) => moment.locale(isSupportedLanguage(lng) ? lng : "en"));
}
isInitialized = true;

export function formatNumber(value: number, options?: Intl.NumberFormatOptions) {
  return Intl.NumberFormat(i18n.language, options).format(value);
}
