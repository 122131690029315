import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageRender from "./pages/Render";
import { PageSmartProfile } from "./pages/SmartProfile";
import { PageSmartLeadRedirect } from "./pages/SmartLeadRedirect";
import { SCThemeProvider } from "./theme/SCThemeProvider";
import { DisplayContextProvider } from "./contexts/DisplayContextProvider";
import { MousePositionProvider } from "./contexts/MousePositionContextProvider";
import { GlobalFontStyles } from "./theme/fonts";
import { MomangFavicon } from "./components/MomangFavicon";
import { GoogleAnalytics } from "./components/GoogleAnalytics";
import { AuthorizationCode } from "./components/AuthorizationCode";
import { NoMansLand } from "./components/NoMansLand";
import { AnimatedAppLoader } from "./components/AnimatedAppLoader";
import { MomangErrorBoundary } from "./render-templates/MomangErrorBoundary";

const MomangAuthenticatorLoader = React.lazy(() => import("./MomangAuthenticatorLoader"));
const PublicApiDocs = lazy(() => import("./components/PublicApiDocs"));

export default function MomangLoader() {
  return (
    <Suspense fallback={<AnimatedAppLoader />}>
      <MomangErrorBoundary>
        <MomangFavicon />
        <SCThemeProvider>
          <GlobalFontStyles />
          <DisplayContextProvider>
            <MousePositionProvider>
              <Router>
                <Routes>
                  <Route path="/render/:profileId" element={<PageRender />} />
                  <Route path="/smart-profiles/:shareSlug" element={<PageSmartProfile />} />
                  <Route path="/smart-leads/:leadShareSlug" element={<PageSmartLeadRedirect />} />
                  <Route path="/oauth2/authorization-code" element={<AuthorizationCode />} />
                  <Route path="/no-mans-land" element={<NoMansLand />} />
                  <Route path="/api-docs" element={<PublicApiDocs />} />
                  <Route path="*" element={<MomangAuthenticatorLoader />} />
                </Routes>
                <GoogleAnalytics />
              </Router>
            </MousePositionProvider>
          </DisplayContextProvider>
        </SCThemeProvider>
      </MomangErrorBoundary>
    </Suspense>
  );
}
