import { AxiosCacheInstance, buildMemoryStorage, CacheRequestConfig, setupCache } from "axios-cache-interceptor";
import { getJwt } from "./auth";
import axios from "axios";

export const binaryDownloadOptions: CacheRequestConfig = {
  cache: false,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/pdf",
  },
};

type CacheContextName = string;
type CacheId = string;

type MomangCacheRequestConfig = CacheRequestConfig & {
  cacheContext?: CacheContextName;
};

/**
 * Axios client with authentication and caching support.
 *
 * The cache is implemented using the axios-cache-interceptor package.
 * Invalidating parts of the cache seems to be a bit tricky, so we introduce the concept of
 * cache contexts where we can keep track of each cache entry and invalidate them when needed.
 */
export class MomangAxiosClient {
  axiosClient: AxiosCacheInstance;
  cacheContexts = new Map<CacheContextName, Set<CacheId>>();

  constructor(baseUrl: string, cacheOptions?: { ttl: number }) {
    this.axiosClient = setupCache(axios.create({ baseURL: baseUrl }), {
      ttl: cacheOptions?.ttl || 0,
      /**
       * The default header interpreter will use the `cache-control` header to determine the
       * TTL value. Serverless offline seems to automatically send a `cache-control` header with a value of
       * no-cache`, effectively disabling the cache. AWS API Gateway does not send a `cache-control`
       * header by default, so disabling the header interpreter is not strictly necessary there.
       */
      interpretHeader: false,
    });
    this.axiosClient.interceptors.request.use(async (config) => {
      config.headers.Authorization = await getJwt();
      return config;
    });
  }

  async get(url: string, config?: MomangCacheRequestConfig) {
    const result = await this.axiosClient.get(url, config);
    if (config?.cacheContext) {
      const cacheContext = this.cacheContexts.get(config.cacheContext);
      if (cacheContext) cacheContext.add(result.id);
      else this.cacheContexts.set(config.cacheContext, new Set([result.id]));
    }
    return result.data;
  }

  async getBinary(url: string) {
    return this.axiosClient.get(url, binaryDownloadOptions);
  }

  async put(url: string, data: any, config?: CacheRequestConfig) {
    return this.axiosClient.put(url, data, config);
  }

  async post(url: string, data: any, config?: CacheRequestConfig) {
    return this.axiosClient.post(url, data, config);
  }

  async delete(url: string, config?: CacheRequestConfig) {
    return this.axiosClient.delete(url, config);
  }

  clearCache() {
    this.axiosClient.storage = buildMemoryStorage();
  }

  clearCacheContext(cacheContext: string) {
    const cacheIds = this.cacheContexts.get(cacheContext);
    if (cacheIds) {
      this.cacheContexts.delete(cacheContext);
      cacheIds.forEach((id) => this.axiosClient.storage.remove(id));
    }
  }
}
