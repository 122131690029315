import React, { Component, ReactNode } from "react";
import { Button, Container, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { refreshAccessToken } from "../services/auth";
import { Auth } from "@aws-amplify/auth";
import _ from "lodash";

const FullPage = styled.div`
  min-height: 100vh;
  background-color: #ffd2d5;
`;

type MomangErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
  isMomangAdmin?: boolean;
};

type MomangErrorBoundaryProps = {
  children: ReactNode;
};

export class MomangErrorBoundary extends Component<MomangErrorBoundaryProps, MomangErrorBoundaryState> {
  constructor(props: MomangErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      isMomangAdmin: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps: MomangErrorBoundaryProps) {
    if (this.state.hasError && prevProps !== this.props) this.setState({ hasError: false, error: null });
  }

  componentDidMount(): void {
    Auth.currentSession().then((session) => {
      const payload = session?.getIdToken()?.decodePayload() || {};
      const isMomangAdmin = _.includes(payload["cognito:groups"], "Momang-Admin");
      this.setState({ ...this.state, isMomangAdmin });
    });
  }

  render() {
    if (this.state.hasError) {
      const error = this.state.error && this.state.error.message ? this.state.error.message : "";
      return (
        <FullPage>
          <Container text>
            <Segment>
              <h1>Ooops!</h1>
              <p>Something went wrong and Momang cannot be loaded, sorry!</p>
              <p>
                Reload the page to try again. You can always reach us at{" "}
                <a href="mailto:info@momang.io">info@momang.io</a>.
              </p>
              <Button
                content="Reload"
                onClick={() => {
                  window.location.href = "/";
                }}
              />
              {this.state.isMomangAdmin && (
                <Button
                  onClick={async () => {
                    await refreshAccessToken();
                    window.location.reload();
                  }}
                  content={"Reload as ghost"}
                  icon="snapchat ghost"
                />
              )}
              <h2>Error details</h2>
              <p>
                <code>{error}</code>
              </p>
            </Segment>
          </Container>
        </FullPage>
      );
    }
    return this.props.children;
  }
}
