import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { getAuthenticatedUser, updateUser } from "../services/profileService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isMemberOf } from "../services/authUtils";
import { AnimatedAppLoader } from "../components/AnimatedAppLoader";
import { useActiveTimer } from "../hooks/useActiveTimer";
import { Auth } from "@aws-amplify/auth";
import { gaSetUserRoles } from "../UtilGa";
import { Scope, User, UserRole } from "./models";

type AuthenticatedUserContextType = {
  authenticatedUser?: User;
  isAdmin?: boolean;
  isSales?: boolean;
  isConsultant?: boolean;
  isSubcontractor?: boolean;
  scopes?: Scope[];
  hasScope?: (scope: Scope) => boolean;
  hasSomeScope?: (scopes: Scope[]) => boolean;
  refreshAuthenticatedUser?: () => Promise<void>;
  updateAuthenticatedUser?: (user: Partial<User>) => Promise<void>;
  hasSeenLastInstruction?: boolean;
  dateHasSeenLastNews?: Date;
  isMomangAdmin?: boolean;
};

const AuthenticatedUserContext = createContext({});

export function useAuthenticatedUserContext(): AuthenticatedUserContextType {
  return useContext(AuthenticatedUserContext);
}

export function AuthenticatedUserContextProvider({ children }: { children: ReactNode }) {
  const [authenticatedUser, setAuthenticatedUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const [isMomangAdmin, setIsMomangAdmin] = useState(false);
  const [scopes, setScopes] = useState<string[]>([]);
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getAuthenticatedUser()
      .then((user) => {
        setAuthenticatedUser(user);
        if (user.language) i18n.changeLanguage(user.language);
      })
      .catch((e) => {
        console.error("Failed to get authenticated user", JSON.stringify(e));
        if (e.response.status === 410) navigate("/no-mans-land");
        else throw e;
      })
      .finally(() => setIsLoading(false));
  }, [i18n, navigate]);

  const refreshFromToken = useCallback(() => {
    Auth.currentSession().then((session) => {
      const payload = session?.getIdToken()?.decodePayload() || {};
      setIsMomangAdmin(_.includes(payload["cognito:groups"], "Momang-Admin"));
      setScopes(payload["scopes"]?.split(",") || []);
      gaSetUserRoles(payload["roles"]?.split(",") || []);
    });
  }, []);

  useActiveTimer({
    action: refreshFromToken,
    actionTimeout: 60 * 1000,
    idleTimeout: 60 * 1000,
  });

  const hasScope = useCallback((scope: Scope) => scopes.includes(scope), [scopes]);

  const hasSomeScope = useCallback((scopes: Scope[]) => scopes.some((scope) => hasScope(scope)), [hasScope]);

  const updateAuthenticatedUser = useCallback(
    (user: User) => {
      if (user) updateUser({ ...user, userSlug: authenticatedUser?.slug }).then(setAuthenticatedUser);
    },
    [authenticatedUser?.slug]
  );

  const hasSeenLastInstruction = useMemo(
    () =>
      authenticatedUser?.preferences?.instructionsLastSeenAt &&
      moment(authenticatedUser.preferences.instructionsLastSeenAt).isAfter(moment(t("help.instructions.updatedAt"))),
    [t, authenticatedUser?.preferences?.instructionsLastSeenAt]
  );

  const dateHasSeenLastNews = useMemo(
    () => {
      const date = (authenticatedUser?.preferences?.newsLastSeenAt && moment(authenticatedUser.preferences.newsLastSeenAt).isAfter(moment(t("newProductUpdates.updatedAt"))))
        ? authenticatedUser.preferences.newsLastSeenAt : t("newProductUpdates.updatedAt");
      return date
    }
      ,
    [t, authenticatedUser?.preferences?.newsLastSeenAt]
  );

  const value = useMemo(
    () => ({
      authenticatedUser,
      isAdmin: _.isObject(authenticatedUser) && isMemberOf(authenticatedUser, UserRole.admin),
      isSales: _.isObject(authenticatedUser) && isMemberOf(authenticatedUser, UserRole.sales),
      isConsultant: _.isObject(authenticatedUser) && isMemberOf(authenticatedUser, UserRole.consultant),
      isSubcontractor: _.isObject(authenticatedUser) && isMemberOf(authenticatedUser, UserRole.subcontractor),
      scopes,
      hasScope,
      hasSomeScope,
      refreshAuthenticatedUser: () => getAuthenticatedUser().then(setAuthenticatedUser),
      updateAuthenticatedUser,
      hasSeenLastInstruction,
      dateHasSeenLastNews,
      isMomangAdmin,
    }),
    [authenticatedUser, scopes, hasScope, hasSomeScope, updateAuthenticatedUser, hasSeenLastInstruction, dateHasSeenLastNews, isMomangAdmin]
  );
  return <AuthenticatedUserContext.Provider value={value} children={isLoading ? <AnimatedAppLoader /> : children} />;
}
