import React, { useEffect } from "react";
import _ from "lodash";
import { Label, Transition } from "semantic-ui-react";
import Mousetrap from "mousetrap";
import styled from "styled-components";
import { useKeyboardShortcutContext } from "../contexts/KeyboardShortcutContextProvider";
import ButtonWithTooltip from "./ButtonWithTooltip";

const KeyboardShortcutLabelContainer = styled.span`
  position: absolute;
  transform: translateX(-15px) translateY(-15px);
  z-index: 10;
`;

function KeyboardShortcutLabel({ keyboard }) {
  const { showKeyboardShortcutLabels } = useKeyboardShortcutContext();

  if (!keyboard) return null;

  const labels = keyboard
    .split("+")
    .map((button, index) => <Label key={index} content={_.toUpper(button)} color="yellow" />);

  return (
    <KeyboardShortcutLabelContainer>
      <Transition directional transitionOnMount duration={200} animation="fade" visible={showKeyboardShortcutLabels}>
        <Label.Group size="mini" content={labels} />
      </Transition>
    </KeyboardShortcutLabelContainer>
  );
}

export function MomangButton({ keyboard = undefined, ...props }) {
  useEffect(() => {
    if (keyboard && props.onClick) {
      Mousetrap.bind(keyboard, (event) => props.onClick(event, props));
      return () => Mousetrap.unbind(keyboard);
    }
    return undefined;
  }, [keyboard, props.onClick, props]);

  return (
    <>
      <ButtonWithTooltip size="medium" basic={false} compact={false} {...props} />
      <KeyboardShortcutLabel keyboard={keyboard} />
    </>
  );
}
