import { MomangAxiosClient } from "./momangAxiosClient";
import { getJwtClaim } from "./auth";
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from "./cacheUtils";
import { Moment } from "moment";
import { UserRole } from "../contexts/models";

const analyticsClient = new MomangAxiosClient(process.env.REACT_APP_ANALYTICS_BACKEND_ENDPOINT as string, {
  ttl: FIFTEEN_MINUTES_IN_MILLISECONDS,
});

export async function queryInvoiceData(
  startDate: Moment | string,
  endDate: Moment | string,
  currency: string,
  groupByUnit: boolean
) {
  return analyticsClient.get(`/organizations/${await getJwtClaim("organizationSlug")}/queries/invoice`, {
    params: { startDate, endDate, currency, groupByUnit },
  });
}

export async function queryAllocationData(startDate: Moment | string, endDate: Moment | string, groupByUnit: boolean) {
  return analyticsClient.get(`/organizations/${await getJwtClaim("organizationSlug")}/queries/allocation`, {
    params: { startDate, endDate, groupByUnit, format: "nivo" },
  });
}

export async function queryMonthlyAllocationData(
  startDate: Moment | string,
  endDate: Moment | string,
  groupByUnit: boolean
) {
  return analyticsClient.get(`/organizations/${await getJwtClaim("organizationSlug")}/queries/allocation-monthly`, {
    params: { startDate, endDate, groupByUnit, format: "nivo" },
  });
}

export async function queryCompetenceData(
  badgeShieldSlug: string,
  options?: {
    unit?: string;
    userRoles?: Array<UserRole>;
  }
) {
  return analyticsClient.get(`/organizations/${await getJwtClaim("organizationSlug")}/queries/competence`, {
    params: { badgeShieldSlug, unit: options?.unit, userRoles: options?.userRoles && options.userRoles.join(",") },
  });
}

export function clearAnalyticsCache() {
  analyticsClient.clearCache();
}
