import _ from "lodash";
import Auth from "@aws-amplify/auth";
import moment from "moment";
import { cancelable } from "cancelable-promise";
import { extension } from "mime-types";
import { fetchWithJwt, fileToDataUrl, triggerDownloadFromUrl } from "../Util";
import { MomangAxiosClient } from "./momangAxiosClient";
import { FIFTEEN_MINUTES_IN_MILLISECONDS, ONE_HOUR_IN_MILLISECONDS, TEN_SECONDS_IN_MILLISECONDS } from "./cacheUtils";
import { clearAnalyticsCache } from "./analyticsService";
import { HttpStatusCode } from "axios";
import { UserRole } from "../contexts/models";
import useSWR, { mutate } from "swr";

const axiosClient = new MomangAxiosClient(process.env.REACT_APP_PROFILE_BACKEND_ENDPOINT, {
  ttl: ONE_HOUR_IN_MILLISECONDS,
});

export async function signOut() {
  axiosClient.clearCache();
  await Auth.signOut();
  window.location.reload();
}

export async function getAuthenticatedUser() {
  return axiosClient.get("/users/authenticated", { cacheContext: "user-resources" });
}

export async function getAuthenticatedUserSlug() {
  const authenticatedUser = await getAuthenticatedUser();
  return authenticatedUser.slug;
}

export async function getAuthenticatedUserOrganizationSlug() {
  const authenticatedUser = await getAuthenticatedUser();
  return authenticatedUser.organizationSlug;
}

export async function getOrganization() {
  return axiosClient.get(`/organizations/${await getAuthenticatedUserOrganizationSlug()}`, {
    cacheContext: "organization",
  });
}

export async function updateOrganization(organizationSlug, updatedAttributes) {
  await axiosClient.put(`/organizations/${organizationSlug}`, { ...updatedAttributes, slug: organizationSlug });
  axiosClient.clearCacheContext("organization");
}

export async function getStatistics() {
  const organizationSlug = await getAuthenticatedUserOrganizationSlug();
  return axiosClient.get(`/organizations/${organizationSlug}/statistics`, {
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
  });
}

let searchAbortController;
export async function search(
  query,
  fuzzy,
  userSlugsOnly,
  operator,
  includeRoles = [],
  includeBadges = {},
  includeUnits = [],
  onlyMainProfiles = true
) {
  const organizationSlug = await getAuthenticatedUserOrganizationSlug();
  if (searchAbortController && !searchAbortController.signal.aborted)
    searchAbortController.abort("Cancelled by updated request.");
  searchAbortController = new AbortController();

  // if the 'includeRoles' array is empty, we interpret this as the user intending to search across all available roles
  if (_.isEmpty(includeRoles)) includeRoles = [...Object.keys(UserRole)];
  else includeRoles = [includeRoles];
  return axiosClient.get(`/organizations/${organizationSlug}/search?`, {
    cache: false,
    params: {
      query,
      fuzzy,
      userSlugsOnly,
      operator,
      includeRoles: includeRoles.join(","),
      includeUnits: includeUnits.join(","),
      includeBadges: JSON.stringify(includeBadges),
      onlyMainProfiles,
    },
    signal: searchAbortController.signal,
  });
}

export async function getContentSchema(contentSchemaSlug) {
  const organizationSlug = await getAuthenticatedUserOrganizationSlug();
  return axiosClient.get(`/organizations/${organizationSlug}/content-schemas/${contentSchemaSlug}`);
}

export async function getContentSchemas() {
  const organizationSlug = await getAuthenticatedUserOrganizationSlug();
  return axiosClient.get(`/organizations/${organizationSlug}/content-schemas`);
}

export async function getUser(userSlug, includeEvents = false) {
  return axiosClient.get(`/users/${userSlug}${includeEvents ? "?includeEvents=true" : ""}`, {
    cacheContext: "user-resources",
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
  });
}

export async function getUsers({ roles } = {}) {
  return axiosClient.get(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/users`, {
    cacheContext: "user-resources",
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
    params: {
      roles: roles && roles.join(","),
    },
  });
}

export async function inviteUser(name, email, roles, sendEmailInvitation) {
  const response = await axiosClient.post(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/users`, {
    name,
    email: _.toLower(email),
    roles,
    sendEmailInvitation,
  });
  axiosClient.clearCacheContext("user-resources");
  return response.data;
}

export async function deleteUser(userSlug) {
  const response = await axiosClient.delete(`/users/${userSlug}`);
  axiosClient.clearCacheContext("user-resources");
  return response.status === HttpStatusCode.Ok;
}

export async function resetUserPassword(userSlug) {
  const response = await axiosClient.post(`/users/${userSlug}/reset-password`);
  return response.status === HttpStatusCode.Ok;
}

export async function inviteExistingUser(userSlug) {
  const response = await axiosClient.post(`/users/${userSlug}/invite`);
  axiosClient.clearCacheContext("user-resources");
  return response.status === HttpStatusCode.Ok;
}

export async function updateUser({ userSlug, ...userAttributes }) {
  const updatedUserResponse = await axiosClient.put(`/users/${userSlug}`, userAttributes);
  axiosClient.clearCacheContext("user-resources");
  return updatedUserResponse.data;
}

export async function updateAuthenticatedUser(name, pictureDataUrl, language) {
  const userSlug = await getAuthenticatedUserSlug();
  await updateUser({ userSlug, name, pictureDataUrl, language });
}

export async function getConsultants(
  includeProfiles = true,
  includeLatestEvent = true,
  includeCurrentEvents = true,
  slugs = []
) {
  return axiosClient.get(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/users`, {
    cacheContext: "user-resources",
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
    params: {
      includeProfiles,
      includeLatestEvent,
      includeCurrentEvents,
      slugs: _.isEmpty(slugs) ? undefined : slugs.join(","),
      roles: [UserRole.consultant, UserRole.subcontractor].join(","),
    },
  });
}

export async function getOrganizationEvents(start = undefined, end = undefined, userSlugs = undefined) {
  return axiosClient.get(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/events`, {
    cacheContext: "organization-events",
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
    params: {
      start,
      end,
      userSlugs: userSlugs ? userSlugs.join(",") : undefined,
    },
  });
}

export async function addOrganizationEventOrEvents(eventOrEvents) {
  await axiosClient.post(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/events`, eventOrEvents, {
    cache: { update: { "get-projects": "delete" } },
  });
  axiosClient.clearCacheContext("user-resources");
  axiosClient.clearCacheContext("organization-events");

  clearAnalyticsCache();
}

export async function updateOrganizationEvent(event) {
  await axiosClient.put(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/events/${event.id}`, event, {
    cache: { update: { "get-projects": "delete" } },
  });
  axiosClient.clearCacheContext("user-resources");
  axiosClient.clearCacheContext("organization-events");

  clearAnalyticsCache();
}

export async function deleteOrganizationEvent(event) {
  await axiosClient.delete(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/events/${event.id}`, {
    cache: { update: { "get-projects": "delete" } },
  });
  axiosClient.clearCacheContext("user-resources");
  axiosClient.clearCacheContext("organization-events");

  clearAnalyticsCache();
}

export function useProfiles(userSlug) {
  const url = userSlug ? `${process.env.REACT_APP_PROFILE_BACKEND_ENDPOINT}/users/${userSlug}/profiles` : null;
  const { data, error, isLoading, mutate } = useSWR(url, fetchWithJwt);

  return {
    profiles: data || [],
    isLoading,
    isError: error,
    mutateProfiles: mutate,
  };
}

export function swrMutateProfiles(userSlug) {
  mutate(`${process.env.REACT_APP_PROFILE_BACKEND_ENDPOINT}/users/${userSlug}/profiles`);
}

export async function getProfile(profileId) {
  return axiosClient.get(`/profiles/${profileId}`, { cache: false });
}

export async function getProfileShares(profileId) {
  return axiosClient.get(`/profiles/${profileId}/shares`, { cacheContext: "profile-shares" });
}

export async function createProfileShare(profileId) {
  const response = await axiosClient.post(`/profiles/${profileId}/shares`);
  axiosClient.clearCacheContext("profile-shares");
  return response.data;
}

export async function updateProfileShareAvailability(profileId, shareSlug, availableUntil) {
  const response = await axiosClient.put(`/profiles/${profileId}/shares/${shareSlug}`, { availableUntil });
  axiosClient.clearCacheContext("profile-shares");
  return response.data;
}

export async function deleteProfileShare(profileId, shareSlug) {
  await axiosClient.delete(`/profiles/${profileId}/shares/${shareSlug}`);
  axiosClient.clearCacheContext("profile-shares");
}

export async function getProfileShareLog(profileId, shareSlug) {
  return axiosClient.get(`/profiles/${profileId}/shares/${shareSlug}/log`, {
    cache: { ttl: TEN_SECONDS_IN_MILLISECONDS },
  });
}

export async function getDefaultRenderTemplate({ organizationSlug, contentSchemaSlug }) {
  const url = `/organizations/${organizationSlug}/content-schemas/${contentSchemaSlug}/render-templates`;
  return (await axiosClient.get(url))[0];
}

function getProfileObjectUrl(profileId, fileType) {
  return cancelable(
    new Promise((resolve) => {
      axiosClient
        .getBinary(`/profiles/${profileId}/${fileType}`)
        .then((response) => resolve(window.URL.createObjectURL(new Blob([response.data]))));
    })
  );
}

export function getProfilePdfObjectUrl(profileId) {
  return getProfileObjectUrl(profileId, "pdf");
}

export function getProfileDocxObjectUrl(profileId) {
  return getProfileObjectUrl(profileId, "docx");
}

export async function downloadProfilePdf(profileId, downloadAsFileName = "profile") {
  const url = await getProfilePdfObjectUrl(profileId);
  triggerDownloadFromUrl(url, `${downloadAsFileName}.pdf`);
}

export async function downloadProfileDocx(profileId, downloadAsFileName = "profile") {
  const url = await getProfileDocxObjectUrl(profileId);
  triggerDownloadFromUrl(url, `${downloadAsFileName}.docx`);
}

export async function addProfile(profile) {
  const result = await axiosClient.post(`/users/${profile.userSlug}/profiles`, profile);
  axiosClient.clearCacheContext("user-resources");

  return result.data;
}

export async function duplicateProfile(profile, t, relationshipData = {}) {
  const deepCopy = _.cloneDeep(profile);
  deepCopy.name = t("editor.duplicateProfileName", { oldName: deepCopy.name, version: moment().format("L") });
  deepCopy.lockedForEditBy = (await getAuthenticatedUser()).email;
  deepCopy.relationshipData = { ...deepCopy.relationshipData, ...relationshipData };
  return addProfile(deepCopy);
}

export async function updateProfile(profile) {
  const result = await axiosClient.put(`/profiles/${profile.id}`, profile);
  axiosClient.clearCacheContext("user-resources");

  return result.data;
}

export function getProfileAttachmentObjectUrl(profileId) {
  return cancelable(
    new Promise((resolve) => {
      axiosClient.getBinary(`/profiles/${profileId}/attachment`).then((response) => {
        const ext = extension(response.headers["content-type"]);
        resolve([window.URL.createObjectURL(new Blob([response.data])), ext]);
      });
    })
  );
}

export async function getProfileAttachmentPdfObjectUrl(profileId) {
  const [file, ext] = await getProfileAttachmentObjectUrl(profileId);
  return ext === "pdf" ? file : null;
}

export function downloadProfileAttachment(profileId) {
  return cancelable(
    new Promise((resolve) => {
      axiosClient.getBinary(`/profiles/${profileId}/attachment`).then((response) => {
        const ext = extension(response.headers["content-type"]);
        resolve(triggerDownloadFromUrl(window.URL.createObjectURL(new Blob([response.data])), `attachment.${ext}`));
      });
    })
  );
}

export async function addProfileAttachment(profileId, file) {
  const fileDataUrl = await fileToDataUrl(file);
  await axiosClient.post(`/profiles/${profileId}/attachment`, fileDataUrl);
  axiosClient.clearCacheContext("user-resources");
}

export async function removeProfileAttachment(profileId) {
  await axiosClient.delete(`/profiles/${profileId}/attachment`);
  axiosClient.clearCacheContext("user-resources");
}

export async function interpretProfileAttachment(profileId) {
  await axiosClient.post(`/profiles/${profileId}/interpret-attachment`);
  axiosClient.clearCacheContext("user-resources");
}

export async function deleteProfile(profileId) {
  await axiosClient.delete(`/profiles/${profileId}`);
  axiosClient.clearCacheContext("user-resources");
}

export async function requestProfileTranslation(profileId, targetLanguage) {
  await axiosClient.post(`/profiles/${profileId}/translate/${targetLanguage}`);
  axiosClient.clearCacheContext("user-resources");
}

export async function getCrmOrganizations() {
  return axiosClient.get(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/crm-organizations`, {
    cacheContext: "crm-organizations",
  });
}

export async function addCrmOrganization(crmOrganization) {
  const result = await axiosClient.post(
    `/organizations/${await getAuthenticatedUserOrganizationSlug()}/crm-organizations`,
    crmOrganization
  );
  axiosClient.clearCacheContext("crm-organizations");
  return result.data;
}

export async function getCrmOrganization(crmOrganizationSlug) {
  return axiosClient.get(`/crm/organizations/${crmOrganizationSlug}`, {
    cacheContext: "crm-organizations",
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
  });
}

export async function updateCrmOrganization(crmOrganization) {
  await axiosClient.put(`/crm/organizations/${crmOrganization.slug}`, crmOrganization);
  axiosClient.clearCacheContext("crm-organizations");
}

export async function deleteCrmOrganization(crmOrganization) {
  await axiosClient.delete(`/crm/organizations/${crmOrganization.slug}`);
  axiosClient.clearCacheContext("crm-organizations");
}

export async function getAllCrmContacts(accountManagerUserSlug) {
  return axiosClient.get(`/organizations/${await getAuthenticatedUserOrganizationSlug()}/crm-contacts`, {
    cacheContext: "crm-contacts",
    params: { accountManagerUserSlug },
  });
}

export async function getCrmContacts(crmOrganizationSlug) {
  return axiosClient.get(`/crm/organizations/${crmOrganizationSlug}/contacts`, { cacheContext: "crm-contacts" });
}

export async function getCrmContact(crmContactSlug) {
  return axiosClient.get(`/crm/contacts/${crmContactSlug}`, { cacheContext: "crm-contacts" });
}

export async function addCrmContact(crmContact) {
  const result = await axiosClient.post("/crm/contacts", crmContact);
  axiosClient.clearCacheContext("crm-contacts");
  return result.data;
}

export async function updateCrmContact(crmContact) {
  await axiosClient.put(`/crm/contacts/${crmContact.slug}`, crmContact);
  axiosClient.clearCacheContext("crm-contacts");
}

export async function deleteCrmContact(crmContact) {
  await axiosClient.delete(`/crm/contacts/${crmContact.slug}`);
  axiosClient.clearCacheContext("crm-contacts");
}

export async function getAllCrmActivities(userSlug = null, includeDone = false, dueDateRange = []) {
  const [dueDateStart, dueDateEnd] = dueDateRange || [];
  const url = `/organizations/${await getAuthenticatedUserOrganizationSlug()}/crm-activities`;
  return axiosClient.get(url, {
    cacheContext: "crm-activities",
    params: { includeDone, userSlug, dueDateStart, dueDateEnd },
  });
}

export async function getCrmActivitiesForCrmOrganization(
  crmOrganizationSlug,
  userSlug = null,
  includeDone = false,
  dueDateRange = []
) {
  const [dueDateStart, dueDateEnd] = dueDateRange || [];
  const url = `/crm/organizations/${crmOrganizationSlug}/activities`;
  return axiosClient.get(url, {
    cacheContext: "crm-activities",
    params: { userSlug, includeDone, dueDateStart, dueDateEnd },
  });
}

export async function getCrmActivitiesForCrmContact(
  crmContactSlug,
  userSlug = null,
  includeDone = false,
  dueDateRange = []
) {
  const [dueDateStart, dueDateEnd] = dueDateRange || [];
  const url = `/crm/contacts/${crmContactSlug}/activities`;
  return axiosClient.get(url, {
    cacheContext: "crm-activities",
    params: { userSlug, includeDone, dueDateStart, dueDateEnd },
  });
}

export async function addCrmActivity(crmActivity) {
  const result = await axiosClient.post("/crm/activities", crmActivity);
  axiosClient.clearCacheContext("crm-activities");
  return result.data;
}

export async function updateCrmActivity(crmActivity) {
  await axiosClient.put(`/crm/activities/${crmActivity.slug}`, crmActivity);
  axiosClient.clearCacheContext("crm-activities");
}

export async function deleteCrmActivity(crmActivity) {
  await axiosClient.delete(`/crm/activities/${crmActivity.slug}`);
  axiosClient.clearCacheContext("crm-activities");
}

export async function getProjects(includeEvents = false, start = undefined, end = undefined) {
  return axiosClient.get("/projects", {
    cacheContext: "projects",
    cache: { ttl: FIFTEEN_MINUTES_IN_MILLISECONDS },
    params: { includeEvents, start, end },
  });
}

export async function addProject(project) {
  const result = await axiosClient.post("/projects", project);
  axiosClient.clearCacheContext("projects");
  return result.data;
}

export async function updateProject(project) {
  const result = await axiosClient.put(`/projects/${project.slug}`, project);
  axiosClient.clearCacheContext("projects");
  return result.data;
}

export async function deleteProject(project) {
  await axiosClient.delete(`/projects/${project.slug}`);
  axiosClient.clearCacheContext("user-resources");
  axiosClient.clearCacheContext("organization-events");
  axiosClient.clearCacheContext("projects");
}

export async function removeUserFromProject(projectSlug, userSlug) {
  await axiosClient.delete(`/projects/${projectSlug}/users/${userSlug}`);
  axiosClient.clearCacheContext("user-resources");
  axiosClient.clearCacheContext("organization-events");
  axiosClient.clearCacheContext("projects");
}

export async function getInternalDownloadAsFileName(profile) {
  const user = await getUser(profile?.userSlug);
  const userName = user?.name || "consultant";
  const profileName = profile?.name || "profile";
  const date = moment(profile?.updatedAt).format("YYYY-MM-DD");
  return `${date} - ${userName} - ${profileName}`;
}

export async function getHighscore() {
  const organizationSlug = await getAuthenticatedUserOrganizationSlug();
  return axiosClient.get(`/organizations/${organizationSlug}/highscore`, {
    cache: { ttl: TEN_SECONDS_IN_MILLISECONDS },
  });
}

export async function addHighscore(score) {
  const organizationSlug = await getAuthenticatedUserOrganizationSlug();
  const result = await axiosClient.put(`/organizations/${organizationSlug}/highscore`, { score });
  return result.data;
}
