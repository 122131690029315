import { useEditorContext } from "../components/profileEditor/ProfileEditorContextProvider";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { getEditorFieldId } from "./Utils";

const PopoverBase = styled.div`
  position: absolute;
  height: ${({ element }) => element?.getBoundingClientRect().height + 8}px;
  width: ${({ element }) => element?.getBoundingClientRect().width + 8}px;
  top: ${({ element }) => element?.getBoundingClientRect().top + getScrollTop(element) - 4}px;
  left: ${({ element }) => element?.getBoundingClientRect().left - 4}px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 100;
`;

const animateBorder = keyframes`
  0% {
    border: 0 solid transparent;
    box-shadow: 0 0 6px 3px transparent;
  }
  50% {
    border: 3px solid #1d299a;
    box-shadow: 0 0 6px 3px #1d299a;
  }
  100% {
    border: 0 solid transparent;
    box-shadow: 0 0 6px 3px transparent;
  }
`;

const PreviewPopover = styled(PopoverBase)`
  backdrop-filter: brightness(0.8); // Darken the background making transparency with white result in the correct color
  background: rgb(212, 217, 249, 0.8);
`;

const FocusedPopover = styled(PopoverBase)`
  animation: ${animateBorder} 2s linear 1;
  pointer-events: none;
`;

const PopoverLabel = styled.div`
  font-size: clamp(0.5rem, 1.5rem, ${(props) => props.height - 16}px);
  font-family: Karla, sans-serif;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
  color: ${(props) => props.theme.color.accent1.base};
  border-radius: 4px;
`;

function getNearestPreviewElement(element) {
  const isLeaf =
    element.dataset?.editorFieldId &&
    element.dataset?.editorFieldId !== "root" &&
    !element.querySelector("[data-editor-field-id]");
  if (isLeaf) return element;
  if (!element.parentNode) return null;
  return getNearestPreviewElement(element.parentNode);
}

function getScrollTop(element) {
  if (!element?.parentElement) return element?.scrollTop;
  return getScrollTop(element?.parentElement);
}

function getRootElement(element) {
  if (!element?.parentElement) return element;
  return getRootElement(element?.parentElement);
}

function findElementsByFieldId(fieldId) {
  const iframe = document.getElementById("iframe")?.contentDocument;
  if (!iframe) return;

  const iframeRoot = iframe.querySelector('[data-editor-field-id="root"]');
  if (!iframeRoot) return;

  const allElements = iframeRoot.querySelectorAll("[data-editor-field-id]");
  return [...allElements].filter((el) => getEditorFieldId(el) === fieldId);
}

function useFocusEditorInput() {
  const { setFocusedEditorFieldId, editor } = useEditorContext();
  const { readOnly } = editor || { readOnly: true };
  const previewElementRef = useRef(null);
  const [editorSelector, setEditorSelector] = useState(null);
  const { t } = useTranslation();

  // Designed to be attached to the onMouseMove event of the parent element of the rendered profile.
  const detectPreviewSection = useCallback(
    (event) => {
      if (readOnly) return;

      if (event.target.id === "preview-element-popover" || event.target.parentElement?.id === "preview-element-popover")
        return;
      const previewElement = getNearestPreviewElement(event.target);
      if (previewElementRef.current === previewElement) return;

      if (previewElement) {
        previewElementRef.current = previewElement;
        setEditorSelector(
          <PreviewPopover id="preview-element-popover" element={previewElement}>
            <PopoverLabel>{t("common.edit")}</PopoverLabel>
          </PreviewPopover>
        );
      } else {
        previewElementRef.current = null;
        setEditorSelector(null);
      }
    },
    [readOnly, t]
  );

  const focusEditorField = useCallback(
    () => setFocusedEditorFieldId?.(getEditorFieldId(previewElementRef.current)),
    []
  );

  return useMemo(
    () => ({
      focusEditorField,
      detectPreviewSection,
      EditorSelector: () => editorSelector,
    }),
    [focusEditorField, detectPreviewSection, editorSelector]
  );
}

function usePreviewHighlights() {
  const { focusedEditorFieldId, highlightedPreviewFieldId, editor } = useEditorContext();
  const { readOnly } = editor || { readOnly: true };
  const [highlights, setHighlights] = useState(null);

  useLayoutEffect(() => {
    if (readOnly || !highlightedPreviewFieldId || focusedEditorFieldId === highlightedPreviewFieldId) return;

    const elements = findElementsByFieldId(highlightedPreviewFieldId);

    if (elements && elements.length > 0) {
      // Scroll firstElement into view only if it is not already visible.
      const firstElement = elements[0];
      firstElement.style.scrollMargin = "20px";
      const shouldScrollUp = firstElement.getBoundingClientRect().top < 0;
      const shouldScrollDown = firstElement.getBoundingClientRect().bottom > getRootElement(firstElement).clientHeight;
      if (shouldScrollUp || shouldScrollDown) firstElement.scrollIntoView({ behavior: "smooth", block: "nearest" });

      setHighlights([...elements].map((element, idx) => () => <FocusedPopover key={idx} element={element} />));
    } else {
      setHighlights([]);
    }
  }, [highlightedPreviewFieldId]);

  return useMemo(
    () => ({
      highlights,
    }),
    [highlights]
  );
}

export function EditorPreviewSync({ children }) {
  const { focusEditorField, detectPreviewSection, EditorSelector } = useFocusEditorInput();
  const { highlights } = usePreviewHighlights();

  return (
    <div onClick={focusEditorField} onMouseMove={detectPreviewSection} data-editor-field-id="root">
      {children}
      <EditorSelector />
      {highlights?.map((Highlight, idx) => (
        <Highlight key={idx} />
      ))}
    </div>
  );
}
