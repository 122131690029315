import React from "react";
import { Icon, Message, Segment } from "semantic-ui-react";
import { Store } from "react-notifications-component";
import { MomangButton } from "../components/MomangButton";
import styled from "styled-components";

export const INFO = "info";
export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "danger";

const iconMap = {
  [INFO]: "info",
  [SUCCESS]: "checkmark",
  [WARNING]: "warning",
  [ERROR]: "stop circle outline",
};

const Pre = styled.pre`
  white-space: pre-wrap;
  font-size: x-small;
  filter: opacity(50%);
`;

function NotificationItem({ title, message, type, technicalMessage, ctaTitle, onCta }) {
  return (
    <Message
      info={type === INFO}
      success={type === SUCCESS}
      warning={type === WARNING}
      error={type === ERROR}
      icon
      onDismiss={() => {}} // Adds a close button, but the actual dismissal is handled by react-notifications-component
    >
      <Icon name={iconMap[type]} />
      <Message.Content style={{ wordBreak: "break-word" }}>
        <Message.Header content={title} />
        {message?.split("\n").map((line, i) => (
          <p key={i}>{line}</p>
        ))}
        {technicalMessage && (
          <code>
            <Pre>{technicalMessage}</Pre>
          </code>
        )}
        {ctaTitle && onCta && (
          <Segment basic textAlign="center">
            <MomangButton content={ctaTitle} onClick={onCta} fluid />
          </Segment>
        )}
      </Message.Content>
    </Message>
  );
}

export function notifyUser(
  title,
  message,
  { type = INFO, technicalMessage = undefined, onRemoval = undefined, ctaTitle, onCta } = {}
) {
  try {
    Store.addNotification({
      content: (
        <NotificationItem
          title={title}
          message={message}
          type={type}
          technicalMessage={technicalMessage}
          ctaTitle={ctaTitle}
          onCta={onCta}
        />
      ),
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeInRight"],
      animationOut: ["animate__animated", "animate__fadeOutRight"],
      onRemoval,
      dismiss: {
        click: true,
        touch: true,
        duration: type === INFO && !onCta ? 10000 : false,
        waitForAnimation: true,
        pauseOnHover: true,
      },
    });
  } catch (err) {
    console.error(err);
  }
}
