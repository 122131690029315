import { Auth } from "@aws-amplify/auth";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import axios from "axios";

let cachedAwsConfig: any;
export async function getAwsConfig() {
  if (!cachedAwsConfig) cachedAwsConfig = (await import(`../aws-exports-${process.env.REACT_APP_STAGE}`)).default;
  return cachedAwsConfig;
}

export async function getUsername() {
  const user = await Auth.currentUserInfo();
  return user.attributes.email;
}

export async function getJwt() {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export async function getJwtClaim(claimName: string) {
  const session = await Auth.currentSession();
  return session.getIdToken().decodePayload()[claimName];
}

export async function changePassword(currentPassword: string, newPassword: string) {
  await Auth.changePassword(await Auth.currentUserPoolUser(), currentPassword, newPassword);
}

export async function refreshAccessToken() {
  Object.keys(localStorage)
    .filter((key) => key.startsWith("CognitoIdentityServiceProvider.") && key.endsWith(".accessToken"))
    .forEach((key) => localStorage.removeItem(key));
  return Auth.currentSession();
}

export async function startAuthSessionFromCode(code: string) {
  const awsConfig = await getAwsConfig();
  const params = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: awsConfig.aws_user_pools_web_client_id,
    redirect_uri: `${process.env.REACT_APP_MOMANG_FRONTEND_BASE_URL}/oauth2/authorization-code`,
    code,
  });
  const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URL}/oauth2/token`, params);
  const sessionData = {
    IdToken: new CognitoIdToken({ IdToken: data.id_token }),
    AccessToken: new CognitoAccessToken({ AccessToken: data.access_token }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: data.refresh_token }),
  };
  const session = new CognitoUserSession(sessionData);
  const cognitoUser = new CognitoUser({
    Username: sessionData.AccessToken.payload.username,
    Pool: new CognitoUserPool({
      UserPoolId: awsConfig.aws_user_pools_id,
      ClientId: awsConfig.aws_user_pools_web_client_id,
    }),
  });
  cognitoUser.setSignInUserSession(session);
}
