import { useCallback, useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";

/**
 * Runs 'action' function every 'actionTimeout' milliseconds, but only when the user is active on the site. User is
 * considered idle after 'idleTimeout' milliseconds.
 */
export function useActiveTimer({ idleTimeout, actionTimeout, action }) {
  const timer = useRef(0);

  const startActionTimer = useCallback(() => {
    if (!timer.current && action && actionTimeout) {
      action();
      timer.current = setInterval(action, actionTimeout);
      return true;
    }
    return false;
  }, [action, actionTimeout]);

  const stopActionTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = 0;
    }
  }, []);

  useEffect(() => {
    if (startActionTimer()) return stopActionTimer;
  }, [startActionTimer, stopActionTimer]);

  useIdleTimer({
    timeout: idleTimeout,
    onActive: startActionTimer,
    onIdle: stopActionTimer,
  });
}
