import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { RichText } from "../../render-templates/common/RichText";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function SmartNdaModal({ nda, onAccept }) {
  const [t] = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("page.smartProfile.ndaPageTitle")}</title>
      </Helmet>
      <Modal open>
        <Modal.Header>{t("page.smartProfile.ndaHeader")}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <RichText html={nda} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("page.smartProfile.ndaAccept")} primary onClick={() => onAccept()} />
        </Modal.Actions>
      </Modal>
    </>
  );
}
