import { Button, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export function SimpleFormValidateField({
  handleSubmit,
  headingText,
  controlledInputLabelText,
  handleControlledInputOnChange,
  controlledValue,
  isValid,
  isNotValidMessage,
  secondInputLabelText,
  handleSecondInputOnChange,
  secondInputValue,
  submitHeading,
}) {
  const [t] = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const form = (
    <Form widths="equal" onSubmit={() => handleSubmit()}>
      <h3>{headingText}</h3>
      <Form.Group>
        <Form.Field required>
          <label>{controlledInputLabelText}</label>
          <Form.Input
            onChange={(_, d) => {
              handleControlledInputOnChange(d);
              setIsDisabled(false);
            }}
            placeholder={controlledInputLabelText}
            value={controlledValue}
            error={
              isValid
                ? false
                : {
                    content: isNotValidMessage,
                    pointing: "above",
                  }
            }
          />
        </Form.Field>
        <Form.Field>
          <label>{secondInputLabelText}</label>
          <Form.Input
            onChange={(_, d) => handleSecondInputOnChange(d)}
            placeholder={secondInputLabelText}
            value={secondInputValue}
          />
        </Form.Field>
      </Form.Group>
      <Button disabled={isDisabled} type="submit">
        {submitHeading}
      </Button>
    </Form>
  );

  return form;
}
