// eslint-disable-next-line
import React, { useEffect } from "react";

function configHyphenopoly() {
  window.Hyphenopoly.config({
    require: {
      sv: "nordvästersjökustartilleriflygspaningssimulatoranläggningsmaterielunderhållsuppföljningssystemdiskussionsinläggsförberedelsearbete",
      en: "Supercalifragilisticexpialidocious",
    },
    setup: {
      selectors: {
        ".Profile": {},
      },
    },
  });
}

/**
 * Injects Hyphenopoly into the DOM so that hyphenation can be used in the headless chrome instance in AWS Lambda.
 */
export function Hyphenopoly() {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "hyphenopoly-script-loader";
    script.type = "text/javascript";
    script.src = "/hyphenopoly/Hyphenopoly_Loader.min.js";
    script.onload = configHyphenopoly;
    document.body.appendChild(script);
  }, []);

  return null;
}
