import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Image, List } from "semantic-ui-react";
import styled from "styled-components";
import wavy2 from "../assets/svgs/wavy2.svg";

const WavyContent = styled.div`
  width: 100%;
  display: flex;
  min-height: 200px;
  background-image: url(${wavy2});
  background-size: cover;
  margin: 15px 0 15px 0;
`;

const Link = styled.a`
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  line-height: 1.1;
  margin: 0;
  color: dimgray;
`;

export function ContactSmartProfile({ user }) {
  const [t] = useTranslation();

  const { name, email, phone, pictureUrl } = user || {};

  if (!name && !email && !pictureUrl && !phone) return null;

  const imageExists = !!pictureUrl;
  return (
    <div>
      <WavyContent>
        <Grid columns={imageExists ? 2 : 1} stackable container verticalAlign="middle">
          {imageExists && (
            <Grid.Column>
              <Image circular src={pictureUrl} size="medium" bordered centered />
            </Grid.Column>
          )}
          <Grid.Column textAlign={imageExists ? "left" : "center"}>
            <List>
              <List.Item>
                <h3>{t("page.smartProfile.contactDetails")}</h3>
              </List.Item>
              <List.Item>
                <h4>{name}</h4>
              </List.Item>
              {email && (
                <List.Item>
                  <span>
                    <Icon name="mail" />
                    <Link href={`mailto:${email}`}>{email}</Link>
                  </span>
                </List.Item>
              )}
              {phone && (
                <List.Item>
                  <span>
                    <Icon name="phone" />
                    <Link href={`tel:${phone}`}>{phone}</Link>
                  </span>
                </List.Item>
              )}
            </List>
          </Grid.Column>
        </Grid>
      </WavyContent>
    </div>
  );
}
