import React from "react";
import Favicon from "react-favicon";
import faviconSource from "../assets/favicons/favicon192.png";

const alertStyles = {
  production: {},
  test: {
    alertCount: "T",
    alertFillColor: "blue",
    alertTextColor: "white",
  },
  development: {
    alertCount: "D",
    alertFillColor: "green",
    alertTextColor: "white",
  },
};

export function MomangFavicon() {
  const { alertCount, alertFillColor, alertTextColor } = alertStyles[process.env.REACT_APP_STAGE] || {};
  return (
    <Favicon
      url={faviconSource}
      alertCount={alertCount}
      alertFillColor={alertFillColor}
      alertTextColor={alertTextColor}
    />
  );
}
