import React from "react";
import { Icon } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { SmartContainer } from "./SmartContainer";
import { SmartHeader } from "./SmartHeader";
import { SmartFooter } from "./SmartFooter";
import { SmartContent } from "./SmartContent";

export function SmartNotFound({ title, header, message }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SmartContainer>
        <SmartHeader />
        <SmartContent basic>
          <h1>
            {header} <Icon name="rain" />
          </h1>
          <p>{message}</p>
        </SmartContent>
        <SmartFooter />
      </SmartContainer>
    </>
  );
}
